import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import TextField from "components/ReactHookForm/TextField"
import { Control } from "react-hook-form"
import SubmitButton from "components/Buttons/SubmitButton"

type Props = {
  control: Control
  isSubmitting: boolean
  isValid: boolean
}

const SendTestMessage = ({ control, isSubmitting, isValid }: Props) => (
  <Box py={8} borderBottomWidth={1} px={6}>
    <Text>Send a Test Message</Text>
    <TextField variant="outline" name="changeMe" control={control} placeholder="+1 833 537 1178" />
    <Flex justifyContent="flex-end">
      <SubmitButton submitting={isSubmitting} disabled={!isValid} text="Send Message" size="sm" />
    </Flex>
  </Box>
)

export default SendTestMessage
