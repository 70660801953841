import React, { useRef } from "react"
import {
  Flex, Input, InputGroup, InputLeftElement, InputRightElement, Button,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import Spinner from "components/elements/Spinner"

type Props = {
  value: string;
  onChange: (value: string) => void;
  loading?: boolean;
  placeholder?: string;
  variant?: "outline" | "unstyled";
}

const SearchField = ({
  value, onChange, loading, placeholder, variant,
}: Props) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleClickClear = () => {
    onChange("")
    inputRef.current?.focus()
  }

  return (
    <Flex w="100%">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon icon="guest-search" color="gray.500" />
        </InputLeftElement>
        <Input
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          variant={variant}
          type="text"
          py={2}
        />
        <InputRightElement>
          {
            loading
              ? <Spinner heightFull size="md" />
              : (
                value.length > 0 && (
                  <Button
                    variant="link"
                    onClick={handleClickClear}
                    cursor="pointer"
                  >
                    <Icon icon="times" color="blue.500" />
                  </Button>
                )
              )
          }
        </InputRightElement>
      </InputGroup>
    </Flex>
  )
}

SearchField.defaultProps = {
  placeholder: "Search",
  variant: "outline",
}

export default SearchField
