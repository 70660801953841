import React, { ReactNode } from "react"
import { Address, CustomField } from "sharedTypes"
import Icon from "components/elements/Icon"
import _ from "lodash"
import { Box, Flex, HStack } from "@chakra-ui/react"
import Link from "components/elements/Link"
import AddressField from "./AddressField"
import EmailField from "./EmailField"

export const sortedValidAttributeTypes = [
  "EmailField",
  "PhoneField",
  "AddressField",
  "TextField",
  "GenderField",
  "BirthDateField",
  "NotesField",
]

const icons = {
  PhoneField: "contacts-phone",
  EmailField: "emails",
  AddressField: "event-where",
  TextField: "contacts-text",
  BirthDateField: "dash-calendar",
  GenderField: "contacts-gender",
  NotesField: "note",
}

const socialOrder = [
  "linkedin",
  "twitter",
  "facebook",
  "instagram",
  "pinterest",
  "flickr",
  "homepage",
  "angellist",
  "vimeo",
]

type Props = {
  items: CustomField[];
  canViewGuestDetails: boolean;
  primaryEmail: string | null;
}

const AttributeList = ({
  items, canViewGuestDetails, primaryEmail,
}: Props) => {
  if (!items?.length) {
    return null
  }

  const emailCount = items.filter(({ type }) => type === "EmailField").length
  const formatValue = ({ type, value }: CustomField) => {
    if (type === "AddressField") {
      return <AddressField value={value as Address} />
    }

    if (type === "EmailField") {
      return (
        <EmailField
          value={value as string}
          primary={emailCount > 1 && value === primaryEmail}
        />
      )
    }

    return value as ReactNode
  }

  const canViewAttributeType = (type) => canViewGuestDetails || !["PhoneField", "EmailField"].includes(type)
  const generalAttributes = items.filter(
    ({ type }) => sortedValidAttributeTypes.includes(type) && canViewAttributeType(type),
  )
  const socialProfiles = _.sortBy(items.filter(({ type }) => type === "SocialField"), ({ title }) => {
    const index = socialOrder.indexOf(title)

    if (index === -1) {
      return socialOrder.length
    }

    return index
  })

  let socialProfilesSection
  if (socialProfiles.length) {
    socialProfilesSection = (
      <Box px={5} pt={6} pb={2} borderTopWidth={1}>
        <Flex mb={5}>
          <Icon icon="social" mr={3} color="gray.300" />
          <Box>
            <Box fontSize="sm" textTransform="capitalize" color="gray.500">Social Profiles</Box>
            <HStack spacing={0} mt={4} mr={-6} mb={-6} color="gray.700" wrap="wrap">
              { socialProfiles.map(({ title, value }, index) => {
                const socialType = title.toLowerCase()

                return (
                  <Box key={index} pr={6} pb={6}>
                    <Link
                      target="_blank"
                      rel="noopener noreferrer"
                      href={value as string}
                      title={_.capitalize(socialType)}
                      color="blue.500"
                    >
                      <Icon
                        icon={socialOrder.includes(socialType) ? socialType : "globe"}
                        size={8}
                        alt={_.capitalize(socialType)}
                      />
                    </Link>
                  </Box>
                )
              }) }
            </HStack>
          </Box>
        </Flex>
      </Box>
    )
  }

  return (
    <>
      <Box pt={6} px={5}>
        {generalAttributes.map((attribute, index) => (
          <Flex key={index} mb={5}>
            <Icon icon={icons[attribute.type] || ""} mr={3} color="gray.300" />
            <Box>
              <Box fontSize="sm" color="gray.500" textTransform="capitalize">{attribute.title}</Box>
              <Box color="gray.800">{formatValue(attribute)}</Box>
            </Box>
          </Flex>
        ))}
      </Box>
      {socialProfilesSection}
    </>
  )
}

export default AttributeList
