import {
  Box, Flex, List, ListItem, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { asNumber } from "utilities/strings"
import StatusBar from "components/elements/StatusBar"
import React from "react"

export const CheckList = (props) => <List pt={8} pb={2} spacing={3} fontSize="md" {...props} />

type CheckListItemProps = {
  text: string
  checked?: boolean
}

export const CheckListItem = ({ text, checked }: CheckListItemProps) => (
  <ListItem>
    <Flex justify="space-between" fontWeight="semibold">
      {text}
      {
        checked
          ? <Icon icon="checkin-filled" color="blue.500" />
          : <Icon icon="cross" color="red.500" />
      }
    </Flex>
  </ListItem>
)

type CheckListStatusProps = {
  text: string
  status: number
  total: number
}

export const CheckListStatus = ({
  text, status, total,
}: CheckListStatusProps) => {
  const remaining = total - status

  return (
    <ListItem>
      <Flex justify="space-between" fontWeight="semibold">{text} <Text color={!remaining ? "red.500" : "inherit"}>{asNumber(status)}/{asNumber(total)}</Text></Flex>
      <Box w="full" mt={1}>
        <StatusBar sections={[
          { size: status, color: !remaining ? "red.500" : "blue.500" },
          { size: remaining, color: "gray.200" },
        ]}
        />
      </Box>
    </ListItem>
  )
}
