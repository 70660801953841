import React from "react"
import { Form, FormType, ModalName } from "sharedTypes"
import { Box, Text, Flex } from "@chakra-ui/react"
import EditorSection from "components/Builder/components/EditorSection"
import Button from "components/Buttons/Button"
import { asDateWithTZ, asMoney } from "utilities/strings"
import useModal from "services/useModal"
import { useCurrentEvent } from "queries/events"

type Props = {
  form: Form
  onChange: (formData: Form) => void
  currency: string
}

const FormAttributesSection = ({ form, onChange, currency }: Props) => {
  const showModal = useModal()
  const {
    name, description, submissionsEndAt, limitPerSubmission, quantity, type, price,
  } = form
  const { data: { timezone } } = useCurrentEvent()

  const formattedSubmissionsEndAt = submissionsEndAt
    ? asDateWithTZ(submissionsEndAt, timezone, "PP, p")
    : null
  const maxPerText = `Max per ${type === FormType.Ticket ? "Order" : "Submission"}`

  return (
    <EditorSection>
      <Box>
        <Text fontSize="xl" fontWeight="bold">{name}</Text>
        <Text color={!description ? "gray.400" : ""}>{description ?? "No description"}</Text>
      </Box>
      <Box mt={8}>
        <AttributeRow label="Closing Time" value={formattedSubmissionsEndAt || "Not specified"} inactive={!formattedSubmissionsEndAt} />
        {type !== FormType.RSVP && limitPerSubmission && (
          <AttributeRow
            label={maxPerText}
            value={limitPerSubmission}
          />
        )}
        {type !== FormType.RSVP && <AttributeRow label="Quantity" value={quantity || "Unlimited"} />}
        {Number(price) > 0 && <AttributeRow label="Price" value={`${currency} ${asMoney(price, currency)}`} /> }
        <Flex justifyContent="center" my={6}>
          <Button variant="outline" size="sm" onClick={() => showModal(ModalName.FormForm, { formId: form.externalId, onSuccess: onChange })}>Edit Attributes</Button>
        </Flex>
      </Box>
    </EditorSection>
  )
}

type AttributeRowProps = {
  label: string;
  value: string | number;
  inactive?: boolean;
}

const AttributeRow = ({ label, value, inactive }: AttributeRowProps) => (
  <Flex my={2}>
    <Text width="50%">{label}</Text>
    <Text width="50%" color={inactive ? "gray.400" : undefined}>{value}</Text>
  </Flex>
)

export default FormAttributesSection
