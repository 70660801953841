import React from "react"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import { FormType, Translations } from "sharedTypes"
import MessagingSection from "../EditFormModalContent/EditFormModalSettings/Messaging"

type Props = {
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  type: FormType
  formId: string
}

const SubmissionSettings = ({
  updateEvent, defaultTranslations, type, formId,
}: Props) => (
  <>
    <SidePanelHeader>Button</SidePanelHeader>
    <MessagingSection
      updateEvent={updateEvent}
      defaultTranslations={defaultTranslations}
      formType={type}
      formId={formId}
    />
  </>
)

export default SubmissionSettings
