import React from "react"
import { Form } from "sharedTypes"
import { Box, Stack } from "@chakra-ui/react"
import FormSection from "pages/EventShow/Forms/FormSection"
import DynamicInputFields from "../DynamicInputFields"

type Props = {
  form: Form;
}

const RegistrationFields = ({ form }: Props) => (
  <Box borderBottomWidth={1}>
    <FormSection first>
      <Stack spacing={4}>
        <DynamicInputFields form={form} />
      </Stack>
    </FormSection>
  </Box>
)

export default RegistrationFields
