import Modal from "components/dialogs/Modal"
import React, { ReactNode, useEffect, useState } from "react"
import { useChargeConfiguration, usePlans } from "queries/plans"
import Spinner from "components/elements/Spinner"
import { useHistory, useParams } from "react-router-dom"
import {
  Box, Divider, Flex, Heading, ListIcon, ListItem, Text, UnorderedList,
} from "@chakra-ui/react"
import CreditCardField from "components/ReactHookForm/CreditCardField"
import * as Yup from "yup"
import { postCharge } from "api/accounts"
import { updateCurrentAccount } from "context/actions"
import { useQueryClient } from "react-query"
import Icon from "components/elements/Icon"
import Panel from "components/elements/Panel"
import PaymentForm, { LineItem } from "components/elements/PaymentForm"
import { asDateWithTZ, asMoney } from "utilities/strings"
import { differenceInMonths } from "date-fns"
import { eventsPath } from "utilities/routes"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { Account } from "sharedTypes"
import Link from "../components/elements/Link"
import UpgradeSuccessful from "./UpgradeSuccessful"

interface Props {
  currentAccount: Account
  onHide: () => {}
}

const Pay = ({ currentAccount, onHide }: Props) => {
  const { chargeConfigurationId } = useParams<{ chargeConfigurationId: string }>()
  const { isFetching, chargeConfiguration } = useChargeConfiguration(chargeConfigurationId)
  const { plan: planType } = chargeConfiguration
  const history = useHistory()
  const {
    subscription, name: accountName, cardBrand, cardLast4,
  } = currentAccount

  useEffect(() => {
    if (subscription && planType) {
      history.push(eventsPath())
    }
  }, [planType])

  return (
    <Modal
      title={<Icon size={30} h={8} icon="diobox-logo" color="blue.500" />}
      show
      hide={onHide}
      size="full"
      noPadding
      closeOnEsc={false}
    >
      <ModalContent
        isFetching={isFetching}
        chargeConfiguration={chargeConfiguration}
        accountName={accountName}
        cardBrand={cardBrand}
        cardLast4={cardLast4}
      />
    </Modal>
  )
}

const ModalContent = ({
  isFetching, chargeConfiguration, accountName, cardBrand, cardLast4,
}) => {
  const [success, setSuccess] = useState(false)

  const queryClient = useQueryClient()
  const { isLoading, plans } = usePlans()
  const {
    description, plan: planType, periodStartsAt, periodEndsAt, amount,
  } = chargeConfiguration
  const { monthlyPrice = 0, features = [] } = plans.find(({ type }) => type === planType) || {}

  const totalMonths = differenceInMonths(new Date(periodEndsAt), new Date(periodStartsAt))
  const discount = (monthlyPrice * totalMonths) - amount

  const onSubmit = async ({ tokenId }: {tokenId?: string | null}) => {
    try {
      const { data, status } = await postCharge(
        chargeConfiguration.externalId,
        { charge: { tokenId } },
      )

      if (status === 200) {
        await updateCurrentAccount(queryClient, data)
        setSuccess(true)
      }
    } catch {
      // API service notifies the user about failure so we don't have to repeat it here
    }
  }

  const PaymentSchema = Yup.object().shape({
    creditCardRequired: Yup.boolean(),
    tokenId: Yup.string().nullable().when("creditCardRequired", {
      is: true,
      then: (schema) => schema.required(),
    }),
  })

  const {
    control, handleSubmit, formState: { isSubmitting, isValid }, watch, setValue,
  } = useForm({
    defaultValues: {
      tokenId: null,
      creditCardRequired: !cardLast4,
    },
    resolver: yupResolver(PaymentSchema),
    mode: "onChange",
  })

  if (isFetching || isLoading) {
    return <Spinner />
  }

  if (success) {
    return <UpgradeSuccessful />
  }

  const creditCardRequired = watch("creditCardRequired")

  if (amount) {
    return (
      <Box px={20} py={14}>
        <Heading fontSize="2xl" fontWeight="semibold">Your Custom Diobox Plan</Heading>
        <Flex>
          <Box flex={1} mt={10} mr={5}>
            <Panel bgColor="gray.100">
              <Text fontWeight="semibold">{description}</Text>
            </Panel>
            <UnorderedList my={8} ml={0}>
              <Item label="Account" value={accountName} />
              <Item label="Plan Details" value={planType}>
                <UnorderedList styleType="none" ml={0} my={4} spacing={1}>
                  {features.map((feature) => (
                    <ListItem key={feature} fontSize="sm">
                      <ListIcon as={Icon} icon="checkmark" color="blue.500" />
                      {feature}
                    </ListItem>
                  ))}
                </UnorderedList>
                <Link target="_blank" href="https://home.d.io/pricing/">See all features <Icon icon="next-arrow" /></Link>
              </Item>
              {periodStartsAt
                && <Item label="Starts On" value={asDateWithTZ(periodStartsAt, "UTC", "MMMM d, yyyy")} />}
              {periodEndsAt
                && <Item label="Expires On" value={asDateWithTZ(periodEndsAt, "UTC", "MMMM d, yyyy")} />}
              <Item label="One-Time Charge" value={asMoney(amount, "USD")} />
            </UnorderedList>
          </Box>
          <>
            <PaymentForm
              isFetching={isFetching}
              submitText={planType ? "Pay & Start Subscription" : `Pay ${asMoney(amount, "USD")}`}
              isSubmitting={isSubmitting}
              isValid={isValid}
              onSubmit={handleSubmit(onSubmit)}
            >
              {planType && (
                <Box py={6}>
                  <LineItem title={planType} value={monthlyPrice * totalMonths} />
                  {discount && <LineItem title="Discount" value={discount} credit />}
                </Box>
              )}
              <Divider />
              <Flex py={8} justify="space-between" fontSize="xl" fontWeight="semibold">
                <Text>Total</Text>
                <Text>{asMoney(amount, "USD")}</Text>
              </Flex>
              <Divider />
              <Heading pt={6} fontSize="sm" fontWeight="normal">Billing information</Heading>
              <Box mb={2} pt={6} pb={8}>
                {creditCardRequired && (
                  <Box borderWidth={1} borderRadius="md" p={3}>
                    <CreditCardField name="tokenId" control={control} />
                  </Box>
                )}
                {cardLast4 && (
                  <>
                    {creditCardRequired
                      && <Heading mt={6} fontSize="sm">Existing card</Heading>}
                    <Text fontWeight="semibold">
                      {`${cardBrand} ending in ${cardLast4} `}
                      {creditCardRequired && (
                        <Link
                          fontWeight="normal"
                          onClick={() => {
                            setValue("tokenId", null)
                            setValue("creditCardRequired", false, { shouldValidate: true })
                          }}
                        >
                          use this card
                        </Link>
                      )}
                    </Text>
                  </>
                )}
                {!creditCardRequired && (
                  <Link onClick={() => setValue("creditCardRequired", true, { shouldValidate: true })}>
                    Enter new card information
                  </Link>
                )}
              </Box>
              <Divider />
            </PaymentForm>
          </>
        </Flex>
      </Box>
    )
  }

  return null
}

const Item = (
  { label, value, children }:
    {label: string; value: string | null; children?: ReactNode;},
) => {
  if (!value) {
    return null
  }

  return (
    <ListItem as={Flex} mb={6}>
      <Text
        w={1 / 5}
        fontSize="md"
        textTransform="uppercase"
        fontWeight="semibold"
        color="gray.500"
        mr={4}
        lineHeight={1.8}
      >
        {label}
      </Text>
      <Box>
        <Text fontSize="xl" fontWeight="semibold">{value}</Text>
        {children}
      </Box>
    </ListItem>
  )
}

export default Pay
