import { ModalName, Table } from "sharedTypes"
import React, { ReactNode } from "react"
import Menu, { MenuDivider, MenuItem } from "components/dialogs/Menu"
import { Link, useHistory } from "react-router-dom"
import { eventEditTablePath, eventSeatingPath, eventTablePath } from "utilities/routes"
import { removeTable } from "context/actions"
import * as api from "api/tables"
import { Box } from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import { useQueryClient } from "react-query"
import useModal from "services/useModal"
import usePermissions from "services/permissions"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"

type Props = {
  table: Table,
  button?: ReactNode,
  buttonText?: string,
  showTableLinks?: boolean,
  isVisible?: boolean,
}

const TableMenu = ({
  table, button, buttonText, showTableLinks, isVisible = true,
}: Props) => {
  const { data: { id: eventId } } = useCurrentEvent()
  const queryClient = useQueryClient()
  const showModal = useModal()
  const history = useHistory()
  const { canEditSeating } = usePermissions()

  const {
    id, externalId, name,
  } = table

  const handleDelete = () => {
    api.deleteTable(eventId, table).then(() => {
      history.push(eventSeatingPath(eventId))
      removeTable(queryClient, eventId, table.id.toString())
    })
  }
  const viewTable = <TableMenuItem as={Link} to={eventTablePath(eventId, table.id)} icon="eye">View Table</TableMenuItem>
  const editSeats = <TableMenuItem as={Link} to={eventEditTablePath(eventId, table.id)} icon="website-edit">Edit Seats</TableMenuItem>
  const printTable = <TableMenuItem as="a" target="_blank" href={`${import.meta.env.VITE_WEBSITE_HOST}/t/${externalId}`} icon="dash-export">Print/Share Table</TableMenuItem>

  const tableLinks = showTableLinks ? [viewTable, editSeats] : []
  const menuItems = [
    ...tableLinks,
    <TableMenuItem as="button" onClick={() => showModal(ModalName.TableForm, { id })} icon="settings-rotated">Update Attributes</TableMenuItem>,
    <MenuDivider />,
    printTable,
    <MenuDivider />,
    <ConfirmDelete
      type="Table"
      name={name}
      onClickConfirm={handleDelete}
    >
      <TableMenuItem as="button" color="red.400" icon="times">Delete Table</TableMenuItem>
    </ConfirmDelete>,
  ]
  const unprivilegedMenuItems = showTableLinks ? [viewTable, printTable] : [printTable]

  if (!isVisible) {
    return <Box w={200} h={4} bg="gray.100" />
  }

  return (
    <Menu
      button={button}
      buttonText={buttonText}
      menuItems={canEditSeating ? menuItems : unprivilegedMenuItems}
    />
  )
}

TableMenu.defaultProps = {
  showTableLinks: false,
}

type TableMenuItemProps = {
  icon: string;
  children: ReactNode;
  color?: string;
  onClick?: () => void;
} & (
  { as: typeof Link; to: any; }
  | { as: "a"; href: string; target: "_blank"; }
  | { as: "button"; }
  )

const TableMenuItem = ({ children, ...rest }: TableMenuItemProps) => (
  <MenuItem fontSize="md" color="blue.400" {...rest}>{children}</MenuItem>
)

TableMenuItem.defaultProps = {
  color: "blue.400",
}

export default TableMenu
