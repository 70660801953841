import { Box, Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import React, { ReactNode } from "react"

type Props = {
  icon: string
  children: string | ReactNode
}

const FilterOptionLabel = ({ icon, children }: Props) => (
  <Flex alignItems="center">
    <Box mr={2}>
      <Icon icon={icon} />
    </Box>
    <Text fontWeight="semibold">{children}</Text>
  </Flex>
)

export default FilterOptionLabel
