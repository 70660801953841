import React from "react"
import { Box, Text } from "@chakra-ui/react"
import LabelRedefinition from "modals/FormForm/LabelRedefinition"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { DioboxEvent, Translations } from "sharedTypes"
import TitleWithSubtitle from "../../TitleWithSubtitle"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const OtherPhrasesSection = ({ event, updateEvent, defaultTranslations }: Props) => {
  const onSubmit = (values) => updateEvent(values)

  return (
    <Box>
      <TitleWithSubtitle
        title="Other Phrases"
        subtitle="Customize the other terms or phrases in the Guest Portal."
        mb={5}
      />
      <ContainedForm values={event} onSubmit={onSubmit} name="Other Phrases">
        {({ control }) => (
          <Box>
            <Box mb={12}>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.eventActivity}
                name="translations.eventActivity"
                control={control}
              />
              <Text>The header that appears on the Guest Portal if a
                guest has any form submissions.
              </Text>
            </Box>
            <Box mb={12}>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.qrCodeForCheckIn}
                name="translations.qrCodeForCheckIn"
                control={control}
              />
              <Text>The help text that appears below the QR codes on the
                confirmation pages and email notifications.
              </Text>
            </Box>
            <Box mb={12}>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.editProfileHeader}
                name="translations.editProfileHeader"
                control={control}
              />
              <Text>The help text that appears on top of the Guest Profile Edit
                page.
              </Text>
            </Box>
            <Box mb={12}>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.editProfile}
                name="translations.editProfile"
                control={control}
              />
              <Text>The header and the menu item that allows a guest to edit
                their profile.
              </Text>
            </Box>
            <Box mb={12}>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.emailNotifications}
                name="translations.emailNotifications"
                control={control}
              />
              <Text>The header and the menu item that allows a guest to
                change their email subscription settings.
              </Text>
            </Box>
            <LabelRedefinition
              defaultTranslation={defaultTranslations.uploadPhoto}
              name="translations.uploadPhoto"
              control={control}
            />
            <Text>The header and the button that allows a guest to upload
              their own photo.
            </Text>
          </Box>
        )}
      </ContainedForm>
    </Box>
  )
}

export default OtherPhrasesSection
