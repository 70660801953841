import React from "react"
import { ButtonProps } from "@chakra-ui/react"
import Button from "./Button"

type Props = {
  disabled?: boolean;
  submitting?: boolean;
  colorScheme?: ButtonProps["colorScheme"]
  size?: ButtonProps["size"]
  text?: string;
}

const SubmitButton = ({
  disabled, submitting, colorScheme, size, text,
}: Props) => (
  <Button
    type="submit"
    isLoading={submitting}
    isDisabled={disabled}
    colorScheme={colorScheme}
    size={size}
  >
    { text }
  </Button>
)

SubmitButton.defaultProps = {
  colorScheme: "button.primary",
  size: "md",
  disabled: false,
  submitting: false,
  text: "Submit",
}

export default SubmitButton
