import React from "react"
import {
  Box, Flex, Stack, StackDivider, Text,
} from "@chakra-ui/react"
import { Transaction } from "sharedTypes"
import { deleteTransaction } from "api/guests"
import {
  refetchSubmissions,
  removeFormSubmissionLineItem,
  removeGuestTransaction,
} from "context/actions"
import { useHistory, useParams } from "react-router-dom"
import { useQueryClient } from "react-query"
import { deleteFormSubmissionLineItem } from "api/forms"
import { eventSubmissionsPath } from "utilities/routes"
import Button from "../Buttons/Button"
import ConfirmDelete from "./Confirm/ConfirmDelete"

export const ActivityPopoverContent = ({ children }) => (
  <Stack
    divider={<StackDivider />}
    spacing={0}
  >
    {children}
  </Stack>
)

export const ActivityPopoverSection = ({ children }) => (
  <Box p={5}>
    {children}
  </Box>
)

export const ActivityPopoverRow = ({ label, value }) => (
  <Flex py={1.5}>
    <Text lineHeight={1} fontWeight="semibold" w="34%" flexShrink={0} mr={2}>{label}</Text>
    <Text lineHeight={1} wordBreak="break-word">{value}</Text>
  </Flex>
)

type DeleteTransactionProps = {
   item: Transaction
}

export const ActivityPopoverDeleteTransaction = ({
  item,
  item: {
    id, guestId, displayType, type,
  },
}: DeleteTransactionProps) => {
  const { eventId } = useParams<{ eventId: string }>()
  const queryClient = useQueryClient()

  const handleDelete = () => {
    deleteTransaction(eventId, guestId, id)
      .then(() => {
        removeGuestTransaction(queryClient, item)
      })
  }

  let confirmDeleteProps
  switch (type) {
    case "EmailTransaction": {
      confirmDeleteProps = { type: "Guest Activity", name: displayType }
      break
    }
    case "AttendanceTransaction": {
      confirmDeleteProps = { type: "Guest Activity" }
      break
    }
    default: {
      confirmDeleteProps = { type: displayType }
      break
    }
  }

  return (
    <ConfirmDelete
      {...confirmDeleteProps}
      onClickConfirm={handleDelete}
    >
      <DeleteButton />
    </ConfirmDelete>
  )
}

type ActivityPopoverDeleteFormSubmissionLineItemProps = {
  externalId: string
  name: string,
  guestId: string
  formSubmissionExternalId: string
}

export const ActivityPopoverDeleteFormSubmissionLineItem = ({
  externalId,
  name,
  guestId,
  formSubmissionExternalId,
}: ActivityPopoverDeleteFormSubmissionLineItemProps) => {
  const { eventId } = useParams<{ eventId: string }>()
  const queryClient = useQueryClient()
  const history = useHistory()

  const handleDelete = () => {
    deleteFormSubmissionLineItem(eventId, externalId)
      .then(() => {
        const { lineItems } = removeFormSubmissionLineItem(
          queryClient,
          formSubmissionExternalId,
          externalId,
          guestId,
        )

        if (lineItems?.length === 0) {
          refetchSubmissions(queryClient, eventId)
          history.push(eventSubmissionsPath(eventId))
        }
      })
  }

  return (
    <ConfirmDelete
      type="Form Submission Line Item"
      name={name}
      onClickConfirm={handleDelete}
    >
      <DeleteButton />
    </ConfirmDelete>
  )
}

const DeleteButton = (props) => (
  <Button
    colorScheme="button.danger"
    variant="ghost"
    size="lg"
    {...props}
  >
    Delete
  </Button>
)
