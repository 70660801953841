import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import { FormStatus, FormType } from "sharedTypes"

type Props = {
    isPrivate: boolean
    status: FormStatus
    type: FormType
}

const Visibility = ({ isPrivate, status, type }: Props) => {
  const [visibility, description] = getFormVisibility(isPrivate, status, type)

  return (
    <Flex alignItems="flex-end">
      {visibility && <Box mr={4}><Text fontSize="xl" lineHeight={4}>{visibility}</Text></Box>}
      {description && (
      <Text lineHeight={3}>{description}</Text>
      )}
    </Flex>
  )
}

export const getFormVisibility = (isPrivate: Boolean, status: FormStatus, type: FormType) => {
  let visibility
  let description
  if ([FormType.Ticket, FormType.Registration, FormType.Waitlist].includes(type)) {
    if (!isPrivate) {
      visibility = "Public"
      description = "Listed on Guest Portal"
    } else {
      visibility = "Private"
    }
  }
  if (type === FormType.RSVP) { // Or Survey
    description = "Distributed through Diobox Emails"
  }

  if (status === FormStatus.Disabled) {
    visibility = "Not visible"
    description = null
  }

  return [visibility, description]
}

export default Visibility
