import React from "react"
import { Avatar as ChakraAvatar } from "@chakra-ui/react"
import { ChakraProps } from "@chakra-ui/system"

type Props = {
  name: string;
  src?: string;
  borderRadius?: "none" | "sm" | "base" | "md" | "lg" | "xl" | "2xl" | "3xl";
  size?: "2xs" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "full";
} & ChakraProps

const Avatar = ({
  name, src, size, borderRadius, ...props
}: Props) => (
  <ChakraAvatar
    bgColor="gray.400"
    icon={<></>}
    name={name}
    src={src}
    borderRadius={borderRadius}
    size={size}
    bg="gray.300"
    {...props}
  />
)

Avatar.defaultProps = {
  borderRadius: "full",
  size: "md",
}

export default Avatar
