import React, { ReactNode } from "react"
import { FormLabel, Input, SimpleGrid } from "@chakra-ui/react"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  children: { button: (selected: boolean) => ReactNode; value: string; }[];
  columns?: number;
  spacing?: number;
}

const ToggleButtons = ({
  name, control, children, columns, spacing = 4,
}: Props) => {
  const { field } = useController({ control, name })

  return (
    <SimpleGrid mb={8} spacing={spacing} columns={columns}>
      {
          children.map(({ button, value }) => {
            const selected = field.value === value

            return (
              <FormLabel
                cursor="pointer"
                position="relative"
                overflow="hidden"
                key={value}
                htmlFor={`${name}-${value}`}
                m={0}
              >
                <Input
                  type="radio"
                  position="absolute"
                  left={-100}
                  top={-100}
                  id={`${name}-${value}`}
                  name={name}
                  value={value}
                  onChange={((e) => {
                    if (e.target.checked) {
                      field.onChange(e.target.value)
                    }
                  })}
                  checked={selected}
                />
                {button(selected)}
              </FormLabel>
            )
          })
        }
    </SimpleGrid>
  )
}

ToggleButtons.defaultProps = {
  columns: 3,
}

export default ToggleButtons
