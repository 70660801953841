import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { DioboxEvent, TextMessage } from "sharedTypes"
import { useTextMessage } from "queries/texts"
import Spinner from "components/elements/Spinner"
import { useMutation, useQueryClient } from "react-query"
import * as api from "api/texts"
import { refetchTextMessages, updateTextMessages } from "context/actions"
import { eventTextingPath } from "utilities/routes"
import DraftTextMessage from "./DraftTextMessage/DraftTextMessage"
import SentTextMessage from "./SentTextMessage/SentTextMessage"

export const TEXT_MESSAGE_BODY_CHARACTER_LIMIT = 180

type Props = {
  textMessageId: string
  event: DioboxEvent
}
const SelectedTextMessage = ({ textMessageId, event: { id: eventId }, event }: Props) => {
  const { isLoading, data: selectedTextMessage } = useTextMessage(eventId, textMessageId)
  const queryClient = useQueryClient()
  const history = useHistory()
  const [showSavedLabel, setShowSavedLabel] = useState(false)

  const { mutate: handleDelete } = useMutation(
    () => api.deleteTextMessage(eventId, textMessageId), {
      onSuccess: () => {
        refetchTextMessages(queryClient, eventId)
        history.push(eventTextingPath(eventId))
      },
    },
  )

  const handleSave = (textMessage: TextMessage) => api.putTextMessage(
    eventId, textMessageId, textMessage,
  ).then(({ data }) => {
    setShowSavedLabel(true)
    setTimeout(() => {
      setShowSavedLabel(false)
    }, 2000)
    updateTextMessages(queryClient, textMessageId, data)
    refetchTextMessages(queryClient, eventId)
  })

  if (isLoading) {
    return <Spinner />
  }

  if (!selectedTextMessage) {
    return null
  }

  if (selectedTextMessage?.sentAt) {
    return (
      <SentTextMessage
        selectedTextMessage={selectedTextMessage}
        onSave={handleSave}
        onDelete={handleDelete}
        event={event}
      />
    )
  }

  return (
    <DraftTextMessage
      event={event}
      selectedTextMessage={selectedTextMessage}
      onSave={handleSave}
      onDelete={handleDelete}
      showSavedLabel={showSavedLabel}
    />
  )
}

export default SelectedTextMessage
