import React from "react"
import { Flex, Spinner as ChakraSpinner } from "@chakra-ui/react"

type Props = {
  heightFull?: boolean;
  size?: string;
}

const Spinner = ({ heightFull, size }: Props) => (
  <Flex justify="center" h={heightFull ? "100%" : "100vh"}>
    <ChakraSpinner size={size} color="blue.500" thickness="4px" alignSelf="center" />
  </Flex>
)

Spinner.defaultProps = {
  heightFull: false,
  size: "xl",
}

export default Spinner
