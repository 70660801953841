import React from "react"
import {
  ActivityItem,
  CheckInTransaction,
  FormSubmissionLineItem,
  EmailTransaction,
  Transaction,
} from "sharedTypes"
import EmailItem from "./Invitation"
import GeneralItem from "./General"
import CheckIn from "./CheckIn"
import FormSubmissionItem from "./FormSubmission/FormSubmission"

type Props = {
  item: ActivityItem
  timeZone: string
}

const Item = ({ item, timeZone }: Props) => {
  switch (item.type) {
    case "AttendanceTransaction":
      return <CheckIn item={item as CheckInTransaction} />
    case "EmailTransaction":
      return <EmailItem item={item as EmailTransaction} />
    case "FormSubmission":
      return <FormSubmissionItem item={item as FormSubmissionLineItem} timeZone={timeZone} />
    default:
      return <GeneralItem item={item as Transaction} />
  }
}

export default Item
