import React from "react"
import {
  Box,
  Checkbox,
  CheckboxGroup,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Text,
} from "@chakra-ui/react"
import { Form, FormType } from "sharedTypes"
import Button from "components/Buttons/Button"
import SimpleBar from "simplebar-react"
import { formIconAndType, getFormsByType } from "utilities/forms"
import FilterOptionLabel from "./FilterOptionLabel"

type Props = {
  selectedFormIdFilters: string[]
  onSelectFormIdFilters: (filters) => void
  forms: Form[]
}

const FilterPopover = ({
  selectedFormIdFilters, onSelectFormIdFilters, forms,
}: Props) => {
  const formsByType = (type: FormType) => getFormsByType(forms, type).map((form: Form) => ({
    externalId: form.externalId,
    name: form.name,
  }))

  const filterOptions = [
    {
      title:
  <FilterOptionLabel
    icon={formIconAndType(FormType.Registration).icon}
  >
    {FormType.Registration}
  </FilterOptionLabel>,
      value: FormType.Registration,
      type: FormType.Registration,
      subOptions: formsByType(FormType.Registration),
    },
    {
      title:
  <FilterOptionLabel
    icon={formIconAndType(FormType.Ticket).icon}
  >
    {FormType.Ticket}
  </FilterOptionLabel>,
      value: FormType.Ticket,
      type: FormType.Ticket,
      subOptions: formsByType(FormType.Ticket),
    },
    {
      title:
  <FilterOptionLabel
    icon={formIconAndType(FormType.RSVP).icon}
  >
    {FormType.RSVP}
  </FilterOptionLabel>,
      value: FormType.RSVP,
      type: FormType.RSVP,
      subOptions: formsByType(FormType.RSVP),
    },
    {
      title:
  <FilterOptionLabel
    icon={formIconAndType(FormType.Waitlist).icon}
  >
    {FormType.Waitlist}
  </FilterOptionLabel>,
      value: FormType.Waitlist,
      type: FormType.Waitlist,
      subOptions: formsByType(FormType.Waitlist),
    },
  ]

  const filterGroups = [
    {
      name: "Filter by Form Type",
      options: filterOptions,
    },
    /*
    {
      name: "Filter by Status",
      options: [
        {
          title: "Assigned Ticket",
          value: `${statusQueryKey}assigned-ticket`,
          subOptions: [] },
        {
          title: "Unassigned Ticket",
          value: `${statusQueryKey}unassigned-ticket`,
          subOptions: []
        }],
    }, */
  ]

  const handleAddAllItemsInAGroup = (newItems: string[]) => {
    // Filter out duplicates from the new items
    const uniqueNewItems = newItems.filter((item) => !selectedFormIdFilters.includes(item))

    // If there are all of the items are selected, we remove them to clear the selection
    if (!uniqueNewItems.length) {
      onSelectFormIdFilters((prevItems) => prevItems.filter((item) => !newItems.includes(item)))
    }

    // Update the state with the merged list of existing items and unique new items
    onSelectFormIdFilters((prevItems) => [...prevItems, ...uniqueNewItems])
  }

  return (
    <Menu closeOnSelect={false}>
      <MenuButton>
        <Button
          variant="outline"
          size="sm"
          rightIcon="down-arrow"
        >Filter
        </Button>
      </MenuButton>
      <Portal>
        <MenuList padding={0}>
          <Box as={SimpleBar} w={72} maxH="xl">
            <CheckboxGroup>
              {filterGroups.map(({ name, options }) => (
                <>
                  <Flex
                    position="sticky"
                    top={0}
                    zIndex={1}
                    h={12}
                    justifyContent="center"
                    alignItems="center"
                    backgroundColor="gray.100"
                    borderBottomWidth={1}
                  >
                    <Text fontWeight="semibold">{name}</Text>
                  </Flex>
                  {options.map(({ title, value, subOptions }) => {
                    if (subOptions.length === 0) {
                      return null
                    }

                    const externalIds = subOptions.map(({ externalId }) => externalId)
                    const allChecked = externalIds.every(
                      (item) => selectedFormIdFilters.includes(item),
                    )
                    const isIndeterminate = externalIds.some(
                      (item) => selectedFormIdFilters.includes(item),
                    ) && !allChecked

                    return (
                      <>
                        <Flex
                          h={12}
                          justifyContent="center"
                          alignItems="center"
                          borderBottomWidth={1}
                          pl={4}
                        >
                          <Checkbox
                            w="100%"
                            height="100%"
                            key={value}
                            isChecked={allChecked}
                            isIndeterminate={isIndeterminate}
                            onChange={() => handleAddAllItemsInAGroup(externalIds)}
                          >
                            {title}
                          </Checkbox>
                        </Flex>
                        <CheckboxGroup
                          value={selectedFormIdFilters}
                          onChange={onSelectFormIdFilters}
                        >
                          {subOptions.map(({ name: subOptionName, externalId }) => (
                            <Flex
                              pt={4}
                              pb={3}
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              borderBottomWidth={1}
                              pl={10}
                            >
                              <Checkbox alignItems="flex-start" key={externalId} value={externalId}>
                                <Text mt={-1} noOfLines={1}>{subOptionName}</Text>
                              </Checkbox>
                            </Flex>
                          ))}
                        </CheckboxGroup>
                      </>
                    )
                  })}
                </>
              ))}
            </CheckboxGroup>
          </Box>
        </MenuList>
      </Portal>
    </Menu>
  )
}

export default FilterPopover
