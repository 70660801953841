import React, { useEffect } from "react"
import * as Yup from "yup"
import { useHistory } from "react-router-dom"
import { eventGuestPath } from "utilities/routes"
import { postPlusOnes } from "api/guests"
import { refetchGuests } from "context/actions"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import Select from "components/ReactHookForm/Select"
import pluralize from "pluralize"
import {
  Box,
  Flex, Stack, Text,
} from "@chakra-ui/react"
import { useFieldArray } from "react-hook-form"
import RadioButtons from "components/ReactHookForm/RadioButtons"
import TextField from "components/ReactHookForm/TextField"
import { MAX_PLUS_ONES_COUNT } from "utilities/constants"
import { GuestSummary } from "../sharedTypes"

enum NamingOptions {
  Generic = "generic",
  Specify = "specify",
}

type Props = {
  eventId: string
  guestId: string
  guestName: string
  plusOnesCount: number
  onHide: () => void
}

const PlusOnesSchema = Yup.object({
  plusOnesCount: Yup.number().required(),
  naming: Yup.string().required(),
  plusOnes: Yup.mixed()
    .when("naming", {
      is: NamingOptions.Specify,
      then: () => Yup.array().of(Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string(),
        email: Yup.string().email(),
      })).min(1),
      otherwise: () => Yup.array().of(Yup.mixed()).min(0),
    }),
})

const NewPlusOnes = ({
  eventId, guestId, guestName, plusOnesCount: initialPlusOnesCount, onHide,
}: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  const onSubmit = ({
    naming,
    plusOnesCount,
    plusOnes,
  }: {
      naming: NamingOptions,
      plusOnesCount: number,
      plusOnes: Partial<GuestSummary>[]}) => postPlusOnes(
    eventId,
    guestId,
    naming === NamingOptions.Specify ? { plusOnes } : { plusOnesCount },
  ).then(() => {
    refetchGuests(queryClient, eventId)
    history.push(eventGuestPath(eventId, guestId))
    onHide()
  })

  return (
    <ModalForm
      title="New Plus-Ones"
      onClose={onHide}
      validationSchema={PlusOnesSchema}
      initialValues={{
        plusOnesCount: 1,
        naming: NamingOptions.Generic,
        plusOnes: [{
          firstName: "",
          lastName: "",
          email: "",
        }],
      }}
      onSubmit={onSubmit}
      submitText="Add Plus-Ones"
      size="xl"
      h="80%"
    >
      {({ control, watch }) => {
        const plusOnesCountOptions = [...Array(MAX_PLUS_ONES_COUNT - initialPlusOnesCount)]
          .map((n, index) => {
            const number = index + 1
            const label = `${number} ${pluralize("plus-one", number)}`

            return ({ label, value: number })
          })

        const namingOptions = [
          {
            label: <Text lineHeight={7}>Use generic names ({guestName}&#39;s Plus-One)</Text>,
            value: NamingOptions.Generic,
          },
          {
            label: <Text lineHeight={7}>Specify guest names</Text>,
            value: NamingOptions.Specify,
          },
        ]

        const [count, naming] = watch(["plusOnesCount", "naming"])

        return (
          <>
            <Box w={60} mb={10}>
              <Select label="Add" name="plusOnesCount" control={control} options={plusOnesCountOptions} labelSize="lg" />
            </Box>
            <RadioButtons name="naming" control={control} options={namingOptions} spacing={6} />
            <PlusOnesFields
              control={control}
              count={count}
              show={naming === NamingOptions.Specify}
            />
            <Text mt={6}>You can edit plus-one details after you add them.</Text>
          </>
        )
      }}
    </ModalForm>
  )
}

const PlusOnesFields = ({ control, count, show }) => {
  const {
    fields, append, remove,
  } = useFieldArray<any, any, any>({ name: "plusOnes", control })
  const fieldsCount = fields?.length || 0

  useEffect(() => {
    if (count > fieldsCount) {
      for (let i = 0; i < (count - fieldsCount); i += 1) {
        append({
          firstName: "",
          lastName: "",
          email: "",
        })
      }
    } else {
      for (let i = fieldsCount - 1; i >= count; i -= 1) {
        remove(i)
      }
    }
  }, [count])

  if (!show) {
    return null
  }

  return (
    <Stack spacing={1} mt={8}>
      {fields.map((n, i) => (
        <Flex gap={2} align="center">
          <Text w={7} flexShrink={0} fontWeight="semibold" mb={4}>#{i + 1}</Text>
          <TextField
            variant="outline"
            name={`plusOnes.${i}.firstName`}
            control={control}
            placeholder="First Name"
          />
          <TextField
            variant="outline"
            name={`plusOnes.${i}.lastName`}
            control={control}
            placeholder="Last Name"
          />
          <TextField
            variant="outline"
            name={`plusOnes.${i}.email`}
            control={control}
            placeholder="Email Address"
            type="email"
          />
        </Flex>
      ))}
    </Stack>
  )
}

export default NewPlusOnes
