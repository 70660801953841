import _ from "lodash"
import { BuilderBlockType } from "sharedTypes/builder"
import { camelizeKeys, decamelizeKeys } from "humps"
import { AGENDA_ROW_TYPES } from "./enums"
import {
  AccountUser,
  AgendaRows, EventUser, Guest, GuestSummary, Plan, Speaker, Subscription,
} from "../sharedTypes"

export const NON_DECAMELIZED_KEYS = /^line1$|^line2$|^guest_[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$|^segment_.*$/

/* eslint-disable no-param-reassign */
export const normalizeBools = (object): {} => _.transform(object, (result, value, key) => {
  if (value === "true") {
    result[key] = true
  } else if (value === "false") {
    result[key] = false
  } else {
    result[key] = value
  }
})
/* eslint-enable no-param-reassign */

export const removeFalsy = (object) => {
  const newObj = {}
  Object.keys(object).forEach((prop) => {
    if (object[prop]) { newObj[prop] = object[prop] }
  })

  return newObj
}

export const sortAgendaRows = (agendaRows: AgendaRows) => (
  _.sortBy(agendaRows, ["startsAt", (o) => (o.type === AGENDA_ROW_TYPES.heading ? 0 : 1)])
)

export const sortSpeakers = (speakers: Speaker[]) => (
  _.sortBy(speakers, ["position"])
)

export const sortByOrderList = (
  <T extends { id: number; position: number; }, >(items: T[], order: number[]) => order.map(
    (itemId: number, index) => (
      {
        ...items.find(
          (item) => item.id === itemId,
        ),
        position: index + 1,
      }
    ),
  ).filter((item) => !!item.id) as T[]
)

export const moveElement = (orderList, fromPosition, toPosition) => {
  const newOrder = [...orderList]
  const [movingElement] = newOrder.splice(fromPosition, 1)
  newOrder.splice(toPosition, 0, movingElement)

  return newOrder
}

export const guestSummary = (g: Guest): GuestSummary => {
  const parentGuestId = g.group?.[0]?.id

  return ({
    id: g.id,
    name: `${g.personInfo.firstName} ${g.personInfo.lastName}`,
    checkedIn: g.checkedIn,
    status: g.status,
    photoExists: !!g.personInfo.photoUrl,
    photoUrl: g.personInfo.photoUrl,
    plusOnes: g.id === parentGuestId ? g.group?.filter(({ id }) => id !== g.id) : undefined,
    seatingAssignment: g.seatingAssignment,
    personInfo: g.personInfo,
    waitlistTable: g.waitlistTable,
    standing: g.standing,
    groupSize: g.group?.length,
    guestId: g.id !== parentGuestId ? parentGuestId : undefined,
  })
}

export const isTerminalPlan = (
  plans: Plan[], plan: Subscription | null,
) => plans[plans.length - 1]?.type === plan?.type

export const eventAndAccountAdmins = (eventUsers, accountUsers: AccountUser[] = []) => {
  const confirmedEventUsers = eventUsers
    .filter((u) => u.user?.confirmed)
  const accountAdmins = accountUsers
    .filter(({ admin, user }) => admin && user)

  return _.uniqBy([
    ...confirmedEventUsers,
    ...accountAdmins,
  ], "user.email")
}

export const replyToEmailOptions = (
  eventUsers, accountUsers: AccountUser[] = [],
) => eventAndAccountAdmins(eventUsers, accountUsers)
  .map(({ user: { email } }) => ({ label: email, value: email }))

export const notificationsReplyToEmailOptions = (
  eventUsers: EventUser[], accountUsers: AccountUser[] = [], selectedValue: string,
) => {
  const eventAndAccountAdminOptions = replyToEmailOptions(eventUsers, accountUsers)

  return _.uniqBy([...eventAndAccountAdminOptions,
    { label: selectedValue, value: selectedValue },
  ], "value")
}

export const getDeleteBlockTitle = (blockType: BuilderBlockType) => {
  switch (blockType) {
    case BuilderBlockType.QUESTION:
      return "Question"
    case BuilderBlockType.TEXT:
      return "Text block"
    case BuilderBlockType.IMAGE:
      return "Image"
    case BuilderBlockType.DIVIDER:
      return "Divider block"
    case BuilderBlockType.SPACER:
      return "Spacer block"
    case BuilderBlockType.HEADING:
      return "Headline"
    case BuilderBlockType.BUTTON:
      return "Button"
    case BuilderBlockType.QR_CODE:
      return "QR code"
    case BuilderBlockType.HTML:
      return "HTML block"
    default:
      return ""
  }
}

export const decamelizeObject = (d) => decamelizeKeys(d, (key, convert) => {
  if (NON_DECAMELIZED_KEYS.test(key)) {
    return key
  }
  if (key.match(/\d+/)) {
    return convert(key).split(/(\d+)/).filter(Boolean).join("_")
  }

  return convert(key)
})

export const camelizeObject = (d) => camelizeKeys(d, (key, convert) => {
  if (NON_DECAMELIZED_KEYS.test(key)) {
    return key
  }

  // If key starts with a capital letter, return it unchanged
  if (/^[A-Z]/.test(key)) {
    return key
  }

  return convert(key)
})

export const textRecipientObjectToArray = (object: object) => {
  const decamelizedObject = decamelizeObject(object)

  return _.keys(_.pickBy(decamelizedObject, (value) => value === true))
}
