import React from "react"
import { Control } from "react-hook-form"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { Box } from "@chakra-ui/react"
import BasicInputs from "./BasicInputs"
import DropdownSelect from "./DropdownSelect"
import FormDetailHeader from "./FormDetailHeader"

type Props = {
  control: Control<object, any>
  showUpgradeAlert: boolean
  name: string
}

const Waitlist = ({ control, showUpgradeAlert, name }: Props) => (
  <Box pb={4}>
    <FormDetailHeader
      name={name}
      description="The registration form requires your prospect guests to apply for the event
        first and get on the waiting list. You can then qualify them and decide whether to confirm
        or decline them."
    />
    {showUpgradeAlert && <UpgradeAlert noBorder mb={8} text="Requires Corporate Subscription" />}
    <BasicInputs control={control} showVisibilityInput showQuantityInput>
      <DropdownSelect
        name="limitPerSubmission"
        control={control}
        label="Max per Submission"
      />
    </BasicInputs>
  </Box>
)

export default Waitlist
