import React, { useEffect, useState } from "react"
import {
  FormControl,
  FormLabel,
  InputGroup,
  Slider as ChakraSlider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  min?: number;
  max?: number;
  label?: string;
  disabled?: boolean;
  error?: boolean;
}

const Slider = ({
  name,
  control,
  min,
  max = 100,
  label,
  disabled,
  error,
}: Props) => {
  const { field } = useController({ name, control })
  const [value, setValue] = useState<number>(field.value)

  useEffect(() => {
    if (max < value) {
      setValue(max)
    }
  }, [max])

  return (
    <FormControl mb={4} isInvalid={error} display="flex" alignItems="center">
      {label && <FormLabel fontWeight="bold" w={1 / 4}>{value} {label}</FormLabel>}
      <InputGroup>
        <ChakraSlider
          name={name}
          aria-label={label}
          min={min}
          max={max}
          value={value}
          isDisabled={disabled}
          onChange={(v) => {
            setValue(v)
          }}
          onChangeEnd={(v) => {
            if (v === field.value) {
              return
            }

            field.onChange(v)
          }}
          focusThumbOnChange={false}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <SliderThumb borderWidth="1px" borderColor="gray.400" />
        </ChakraSlider>
      </InputGroup>
    </FormControl>
  )
}

Slider.defaultProps = {
  min: 0,
  max: 100,
  label: null,
  disabled: false,
  error: false,
}

export default Slider
