import React, { ReactNode } from "react"
import { Link } from "react-router-dom"
import { eventGuestPath } from "utilities/routes"
import { getFormSubmissionExternalUrl, getFormSubmissionReceiptExternalUrl } from "utilities/forms"
import { Box } from "@chakra-ui/react"
import { FormType } from "sharedTypes"
import Popover from "./Popover"
import {
  ActivityPopoverContent,
  ActivityPopoverDeleteFormSubmissionLineItem,
  ActivityPopoverRow, ActivityPopoverSection,
} from "./ActivityPopover"
import MenuLink from "./Popover/MenuLink"
import { asDateWithTZ } from "../../utilities/strings"

type Props = {
  trigger: ReactNode
  header: string
  guestId: string
  formType: FormType
  formSubmissionName: string
  isTicket: boolean
  externalId: string
  formSubmissionExternalId: string
  payment: string
  createdAt: string
  timeZone: string
  eventId?: string
}

const FormSubmissionLineItemActivityPopup = ({
  trigger,
  header,
  guestId,
  formType,
  formSubmissionName,
  isTicket,
  externalId,
  formSubmissionExternalId,
  payment,
  createdAt,
  timeZone,
  eventId,
}: Props) => {
  const submitted = asDateWithTZ(createdAt, timeZone) || "N/A"

  return (
    <Popover
      placement="top-end"
      trigger={trigger}
      header={header}
      body={(
        <ActivityPopoverContent>
          <ActivityPopoverSection>
            <ActivityPopoverRow label="Submitted" value={submitted} />
            {isTicket && (
              <ActivityPopoverRow label="Payment" value={payment} />
            )}
            <ActivityPopoverRow label="Reference" value={externalId} />
          </ActivityPopoverSection>
          {eventId && (
            <Link to={`${eventGuestPath(eventId, guestId)}`}>
              <MenuLink icon="guest-profile" label="Guest Profile" />
            </Link>
          )}
          {formType === FormType.Ticket && (
            <Box
              as="a"
              href={getFormSubmissionReceiptExternalUrl(formSubmissionExternalId)}
              target="_blank"
            >
              <MenuLink
                icon="orders-outline"
                label={`${formType === "Ticket" ? "Order" : formType} Receipt`}
                hasRightLinkIcon
              />
            </Box>
          )}
          <Box as="a" href={getFormSubmissionExternalUrl(externalId)} target="_blank">
            <MenuLink
              icon="submission"
              label={`Guest ${formType} Page`}
              hasRightLinkIcon
            />
          </Box>
          <ActivityPopoverDeleteFormSubmissionLineItem
            externalId={externalId}
            name={formSubmissionName}
            guestId={guestId}
            formSubmissionExternalId={formSubmissionExternalId}
          />
        </ActivityPopoverContent>
      )}
    />
  )
}

export default FormSubmissionLineItemActivityPopup
