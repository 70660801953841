import React, {
  ReactElement, useEffect, useState,
} from "react"
import {
  authToken,
  clearSession,
  currentAccountId,
  storeCurrentAccountId, storeCurrentUserData, storeCurrentVersion, supportLogin,
} from "services/authentication"
import { setCurrentUser } from "context/actions"
import { checkAuth, checkVersion } from "api/sessions"
import Spinner from "components/elements/Spinner"
import { useIntercom } from "react-use-intercom"
import { getUnixTime } from "date-fns"
import { QueryClient, useQueryClient } from "react-query"
import { useCurrentUser } from "queries/users"

export const handleAuthResponse = (
  authResponse,
  bootIntercom,
  queryClient: QueryClient,
) => {
  const findOrSetCurrentAccount = () => {
    const account = authResponse.accounts?.find((a) => a.id === currentAccountId())
      || authResponse.accounts[0]
    storeCurrentAccountId(account?.id)

    return account
  }

  if (authResponse) {
    const fullName = [authResponse.firstName, authResponse.lastName].filter(Boolean).join(" ")
    storeCurrentUserData(authResponse.id, authResponse.email, fullName)
    findOrSetCurrentAccount()
    setCurrentUser(queryClient, authResponse)
    if (!supportLogin()) {
      bootIntercom({
        appId: import.meta.env.VITE_INTERCOM_APP_ID,
        email: authResponse.email,
        userId: authResponse.id,
        userHash: authResponse.intercomUserHash,
        name: fullName,
        createdAt: getUnixTime(new Date(authResponse.createdAt)),
      })
    }
  }
}

type Props = {
  children: ReactElement | null
}

const SessionHydrater = ({ children }: Props): ReactElement | null => {
  const [checked, setChecked] = useState(false)
  const { boot: bootIntercom } = useIntercom()
  const queryClient = useQueryClient()
  const currentUser = useCurrentUser()

  useEffect(() => {
    checkVersion().then(async ({ data: { webapp } }) => {
      await storeCurrentVersion(webapp)

      if (authToken()) {
        checkAuth().then(({ data }) => {
          handleAuthResponse(data, bootIntercom, queryClient)
          setChecked(true)
        }, () => {
          clearSession()
          setChecked(true)
        })
      } else {
        setChecked(true)
      }
    })
  }, [])

  if (checked && (authToken() === null || (currentUser.id))) {
    return children
  }

  return <Spinner />
}

export default SessionHydrater
