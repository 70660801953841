import {
  del, get, post, put,
} from "services/api"
import { currentAccountId } from "services/authentication"
import { Guest, Email } from "sharedTypes"
import { PostInvitation } from "sharedTypes/api"

const invitationsBaseURL = (eventId: string) => `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/invitations`

export const getInvitations = (eventId: string) => get<Email[]>(`${invitationsBaseURL(eventId)}.json`, {})

export const postInvitation = (eventId: string, params: PostInvitation) => post<Email>(`${invitationsBaseURL(eventId)}.json`, params)

export const getInvitation = (eventId: string, invitationId: number) => get<Email>(`${invitationsBaseURL(eventId)}/${invitationId}.json`, {})

export const getInvitationEmailCount = (eventId: string, invitationId: number) => get<{emailCount: number}>(`${invitationsBaseURL(eventId)}/${invitationId}/email_count.json`, {})

export const putInvitation = (eventId: string, invitationId: number, invitation: Partial<Email>) => put<Email>(`${invitationsBaseURL(eventId)}/${invitationId}.json`, invitation)

export const duplicateInvitation = (eventId: string, invitationId: number) => post<Email>(`${invitationsBaseURL(eventId)}/${invitationId}/duplicate.json`)

export const deleteInvitation = (eventId: string, invitationId: number) => del<void>(`${invitationsBaseURL(eventId)}/${invitationId}.json`)

export const postInvitationSend = (eventId: string, invitationId: number) => post<Email>(`${invitationsBaseURL(eventId)}/${invitationId}/email.json`)

export const postInvitationSampleEmail = (eventId: string, invitationId: number, invitation: Email) => post<Email>(`${invitationsBaseURL(eventId)}/${invitationId}/sample_email.json`, invitation)

export const getInvitationRecipients = (
  eventId: string, invitationId: number, type: string, page: number,
) => get<Guest[]>(`${invitationsBaseURL(eventId)}/${invitationId}/recipients.json?type=${type}&page=${page}&per_page=50`)

export const postInvitationExport = (eventId: string, invitationId: number) => post<void>(`${invitationsBaseURL(eventId)}/${invitationId}/export.json`)

export const eventInvitationUploadsUrl = (eventId: string, invitationId: number) => `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/emails/${invitationId}/uploads.json`
