import React, { FormEvent, useEffect, useState } from "react"
import {
  Flex, FormControl, FormLabel, Box, Input, HStack, Button,
} from "@chakra-ui/react"
import { ChromePicker } from "react-color"
import { COLOR_SCHEMES } from "utilities/enums"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  colorScheme?: "dark" | "light";
}

const ColorSelect = ({
  name, control, label, disabled, error, colorScheme = "dark",
}: Props) => {
  const { field } = useController({ name, control })
  const [displayPicker, setDisplayPicker] = useState(false)
  const [color, setColor] = useState(field.value)

  useEffect(() => {
    setColor(field.value)
  }, [field.value])

  const handleInput = (e: FormEvent<HTMLInputElement|HTMLButtonElement>) => {
    const hex = (e.target as HTMLInputElement).value
    setColor(hex)
    if (!/^#[0-9A-F]{6}$/i.test(hex)) {
      return
    }

    field.onChange(hex)
  }

  return (
    <FormControl isDisabled={disabled} mb={4} isInvalid={error}>
      <Flex>
        {label && <FormLabel fontWeight="bold" w={1 / 5} flexShrink={0}>{label}</FormLabel>}
        <Flex direction="column" align="start" w="100%">
          <HStack>{COLOR_SCHEMES[colorScheme!].map((c) => (
            <ColorCircle key={c} color={c} selected={color === c} onSelect={handleInput} />
          ))}
          </HStack>
          <Input
            variant="outline"
            mt={4}
            w={28}
            h={9}
            bg="white"
            onFocus={() => setDisplayPicker(true)}
            onChange={handleInput}
            value={color}
          />
          {displayPicker && (
          <>
            <Box
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              onClick={() => setDisplayPicker(false)}
              onScroll={() => setDisplayPicker(false)}
            />
            <Box position="relative" zIndex={2}>
              <ChromePicker
                color={color}
                onChange={(c) => setColor(c.hex)}
                onChangeComplete={(c) => field.onChange(c.hex)}
                presetColors={COLOR_SCHEMES[colorScheme!]}
                disableAlpha
              />
            </Box>
          </>
          )}
        </Flex>
      </Flex>
    </FormControl>
  )
}

type ColorCircleProps = {
  color: string
  onSelect: (e: FormEvent<HTMLButtonElement>) => void
  selected?: boolean
}

const ColorCircle = ({ color, onSelect, selected }: ColorCircleProps) => (
  <Button
    p={0}
    w={7}
    h={7}
    minWidth="none"
    borderRadius="full"
    bgColor={color}
    value={color}
    onClick={onSelect}
    borderColor="gray.400"
    borderWidth={1}
    boxShadow={selected ? "outline" : "none"}
    _hover={{
      bg: "auto",
      opacity: "0.5",
    }}
    _focus={{}}
  />
)

export default ColorSelect
