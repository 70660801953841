import React, { ReactElement } from "react"
import { offset } from "@floating-ui/react"
import {
  Box, CloseButton,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup, InputRightElement,
  Portal,
} from "@chakra-ui/react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "components/inputs/DatePickerField.css"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"
import { useCurrentEvent } from "queries/events"
import {
  Control, ControllerRenderProps, FieldValues, useController,
} from "react-hook-form"
import { isValid } from "date-fns"

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T, object>;
  label?: string;
  placeholder?: string;
  vertical?: boolean;
  utc?: boolean;
  showTimeSelect?: boolean;
  variant?: string;
  disabled?: boolean;
  minDate?: Date;
  helperText?: string;
  showDeleteButton?: boolean
  inline?: boolean
  open?: boolean
  shouldCloseOnSelect?: boolean
  customInput?: ReactElement
  calendarOffset?: number
  renderInPortal?: boolean
}

const DatePickerField = <T extends FieldValues, >({
  name,
  control,
  label,
  placeholder,
  vertical,
  utc,
  showTimeSelect,
  variant = "flushed",
  disabled,
  minDate,
  helperText,
  showDeleteButton = false,
  inline = false,
  open,
  shouldCloseOnSelect,
  customInput,
  calendarOffset = 0,
  renderInPortal,
}: Props<T>) => {
  const {
    data: {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  } = useCurrentEvent()
  const { field } = useController<any>({ name, control })

  let selected: Date | null = null
  if (field.value && isValid(new Date(field.value))) {
    selected = utcToZonedTime(new Date(field.value), utc ? "UTC" : timezone)
  }

  return (
    <FormControl mb={4} display={vertical ? "initial" : "flex"} alignItems="flex-start" isDisabled={disabled}>
      {label && <FormLabel fontWeight="bold" w={vertical ? "auto" : "25%"}>{label}</FormLabel>}
      <Box w="100%">
        <DatePicker
          inline={inline}
          open={open}
          shouldCloseOnSelect={shouldCloseOnSelect}
          showTimeSelect={showTimeSelect}
          showPopperArrow={false}
          selected={selected}
          onChange={(date) => {
            field.onChange(date ? zonedTimeToUtc(date, utc ? "UTC" : timezone) : null)
          }}
          dateFormat={showTimeSelect ? "MMMM d, yyyy h:mm aa" : "MMMM d, yyyy"}
          placeholderText={placeholder}
          customInput={customInput || (
            <CustomInput
              variant={variant}
              field={field}
              disabled={disabled}
              showDeleteButton={showDeleteButton}
            />
          )}
          disabled={disabled}
          minDate={minDate}
          popperModifiers={[
            offset(calendarOffset),
          ]}
          popperContainer={
            renderInPortal
              ? ({ children }) => <Portal>{children}</Portal>
              : undefined
          }
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Box>
    </FormControl>
  )
}

type CustomInputProps = {
  variant: string;
  disabled?: boolean;
  showDeleteButton?: boolean;
  field: ControllerRenderProps<any, string>;
  onChange?: () => void;
  placeholder?: string;
  value?: string;
  id?: string;
  onClick?: () => void
}

const CustomInput = ({
  variant, disabled, showDeleteButton, field, onChange, placeholder, value, id, onClick,
}: CustomInputProps) => (
  <InputGroup>
    <Input
      variant={variant}
      bg="white"
      color={disabled ? "gray.400" : undefined}
      onChange={onChange}
      placeholder={placeholder}
      value={value}
      id={id}
      onClick={onClick}
    />{(showDeleteButton && value) && (
    <InputRightElement w="auto"><CloseButton onClick={() => { field.onChange(null) }} />
    </InputRightElement>
    )}
  </InputGroup>
)

export default DatePickerField
