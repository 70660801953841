import React from "react"
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  LayoutProps,
  Select as ChakraSelect,
  SpaceProps,
  TypographyProps,
} from "@chakra-ui/react"
import { Control, FieldValues, useController } from "react-hook-form"
import _ from "lodash"

type Option = {
  label: string;
  value: string | number;
  disabled?: boolean;
}

type Optgroup = {
  label: string;
  options: Option[];
}

type Props<T extends FieldValues> = {
  name: string
  control: Control<T, object>
  options: (Option | Optgroup)[]
  includeBlank?: boolean
  label?: string
  blankLabel?: string
  labelSize?: TypographyProps["fontSize"]
  onChange?: (event) => void
  helperText?: string
  vertical?: boolean
  disabled?: boolean
  fullWidth?: boolean
  labelWeight?: TypographyProps["fontWeight"]
  labelWidth?: LayoutProps["width"]
  mb?: SpaceProps["margin"]
}

const Select = <T extends FieldValues, >({
  name,
  control,
  options,
  includeBlank,
  label,
  blankLabel,
  labelSize,
  onChange = () => {},
  helperText,
  vertical,
  disabled,
  fullWidth,
  labelWeight = "bold",
  labelWidth,
  mb = 4,
}: Props<T>) => {
  const { field } = useController<any>({ name, control })

  const flattenedOptions = _.flatMap(options, (optionOrOptionGroup) => {
    if ("options" in optionOrOptionGroup) {
      return optionOrOptionGroup.options
    }

    return [optionOrOptionGroup]
  })

  const inputProps = {
    ...field,
    id: name,
    onChange: (event) => {
      onChange(event)
      field.onChange(
        flattenedOptions.find(({ value }) => value.toString() === event.target.value)?.value,
      )
    },
    bg: "white",
  }

  return (
    <FormControl
      id={name}
      mb={mb}
      display={vertical ? "block" : "flex"}
      alignItems="center"
      isDisabled={disabled}
    >
      {label && (
      <FormLabel
        fontSize={labelSize}
        fontWeight={labelWeight}
        w={vertical ? "auto" : labelWidth ?? "25%"}
        mb={vertical ? 0 : 4}
      >{label}
      </FormLabel>
      )}
      <Box w="100%">
        <ChakraSelect maxWidth={fullWidth ? undefined : 300} {...inputProps}>
          {includeBlank && (<option value="" label={blankLabel} selected />)}
          {options.map((opt, index) => {
            if ("options" in opt) {
              return (
                <optgroup key={index} label={opt.label}>
                  {opt.options.map((option) => (
                    <SelectOption
                      key={option.value}
                      label={option.label}
                      value={option.value}
                      disabled={option.disabled}
                    />
                  ))}
                </optgroup>
              )
            }

            return (
              <SelectOption
                key={opt.value}
                label={opt.label}
                value={opt.value}
                disabled={opt.disabled}
              />
            )
          })}
        </ChakraSelect>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Box>
    </FormControl>
  )
}

type SelectOptionProps = {
  label: string | number
  value: string | number
  disabled?: boolean
}

const SelectOption = ({ label, value, disabled }:SelectOptionProps) => (
  <option
    value={value}
    disabled={disabled}
  >{label}
  </option>
)

Select.defaultProps = {
  disabled: false,
  labelSize: "md",
  vertical: false,
}

export default Select
