import React from "react"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import CompactColorSelect from "components/ReactHookForm/CompactColorSelect"
import { DioboxEvent } from "sharedTypes"
import { Stack } from "@chakra-ui/react"
import TitleWithSubtitle from "../../TitleWithSubtitle"
import ThemeSelector from "./ThemeSelector"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  onChangeEventPreview: (values: Partial<DioboxEvent>) => void
}

const ColorTheme = ({
  event: {
    portalPrimaryColor,
    portalTopNavBackgroundColor,
    portalTopNavTextColor,
    portalBackgroundColor,
  }, updateEvent, onChangeEventPreview,
}: Props) => {
  const values = {
    portalPrimaryColor,
    portalTopNavBackgroundColor,
    portalTopNavTextColor,
    portalBackgroundColor,
  }

  return (
    <>
      <TitleWithSubtitle title="Color Theme" subtitle="Customize the colors in the Guest Portal." />
      <ContainedForm values={values} onSubmit={updateEvent} name="Color Theme" onChange={onChangeEventPreview}>
        {({ control, watch, setValue }) => (
          <Stack py={2} spacing={6}>
            <ThemeSelector
              watch={watch}
              setValue={setValue}
            />
            <CompactColorSelect label="Primary Color" name="portalPrimaryColor" control={control} />
            <CompactColorSelect label="Top Navigation Bar Background Color" name="portalTopNavBackgroundColor" control={control} />
            <CompactColorSelect label="Top Navigation Bar Text Color" name="portalTopNavTextColor" control={control} />
            <CompactColorSelect label="Page Background Color" name="portalBackgroundColor" control={control} />
          </Stack>
        )}
      </ContainedForm>
    </>
  )
}

export default ColorTheme
