import React from "react"
import { useHistory } from "react-router-dom"
import { DioboxEvent, ModalName } from "sharedTypes"
import Button from "components/Buttons/Button"
import {
  eventsPath, iOSAppURL,
} from "utilities/routes"
import {
  Box, Flex, Heading, Spacer, Stack,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import RoundedList, { RoundedListItem } from "pages/EventShow/Settings/RoundedList"
import useModal from "services/useModal"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import TextField from "components/ReactHookForm/TextField"
import PlacesAutocompleteField from "components/ReactHookForm/PlacesAutocompleteField"
import TimeZoneSelect from "components/ReactHookForm/TimeZoneSelect"
import ImageUpload from "components/ReactHookForm/ImageUpload"
import { putWebsite } from "api/websites"
import Icon from "components/elements/Icon"
import Checkbox from "components/ReactHookForm/Checkbox"
import AddressFields from "components/ReactHookForm/AddressFields"
import ConfirmDeleteWithCheckboxes from "components/dialogs/Confirm/ConfirmDeleteWithCheckboxes"
import ImageUploadComponent from "components/ImageUploadComponent"
import {
  IMPORTING_GUEST_CSV_ARTICLE_LINK,
  IMPORTING_GUEST_CSV_TEMPLATE_URL,
} from "utilities/externalLinks"
import extendFormValuesWithImages from "utilities/extendFormValuesWithImages"
import { mapValues } from "lodash"

type Props = {
  event: DioboxEvent;
  updateEvent: (params: {}) => Promise<void>;
  cloneEvent: () => Promise<void>;
  deleteEvent: () => Promise<void>;
}

const General = ({
  event, updateEvent, cloneEvent, deleteEvent,
}: Props) => {
  const showModal = useModal()
  const history = useHistory()

  const onSubmit = (values) => updateEvent(values)

  const handleDelete = () => {
    deleteEvent().then(() => {
      history.push(eventsPath())
    })
  }

  return (
    <Stack direction="column" spacing={3}>
      <EventBasics event={event} onSubmit={onSubmit} />
      <Spacer my={3} />
      <ImportExport
        onImport={() => showModal(ModalName.ImportGuests)}
        onExport={() => showModal(ModalName.ExportGuests)}
      />
      <Spacer my={3} />
      <EventControls
        onClone={cloneEvent}
        handleDelete={handleDelete}
        eventName={event.title}
      />
    </Stack>
  )
}

const EventBasics = ({ event, onSubmit }) => {
  const {
    website: { coverImageUrl },
    venue, addressLine1, addressLine2, city, state, zipcode, country,
  } = event
  const handleSubmit = async (values) => {
    const { coverImage } = values.website

    if (coverImage !== undefined) {
      await putWebsite(event.id, { coverImage })
    }

    return onSubmit({
      ...values,
      endDate: values.showEndTime ? values.endDate : null,
    })
  }

  const eventAddress = mapValues({
    venue, addressLine1, addressLine2, city, state, zipcode, country,
  }, (o) => o ?? "")

  return (
    <>
      <SectionHeader>Event Basics</SectionHeader>
      <ContainedForm
        values={{
          ...event,
          ...eventAddress,
          website: extendFormValuesWithImages(event.website, ["coverImage"]),
          showEndTime: !!event.endDate,
        }}
        onSubmit={handleSubmit}
        name="Event Basics"
      >
        {({ control, setValue, watch }) => {
          const onPlacesSelect = (addressComponents) => {
            ["venue", "addressLine1", "city", "state", "zipcode", "country"].forEach((name) => {
              setValue(name, addressComponents[name])
            })
          }
          const showEndTime = watch("showEndTime")

          return (
            <>
              <FormSection icon="event-what">
                <TextField
                  name="title"
                  control={control}
                  label="Event Title"
                  size="2xl"
                />
                <ImageUpload
                  label="Event Image"
                  name="website.coverImage"
                  control={control}
                  imageWidth={46}
                  imageHeight={46}
                  imageUrl={coverImageUrl}
                  Component={ImageUploadComponent}
                />
              </FormSection>
              <FormSection icon="dash-calendar">
                <DatePickerField
                  showTimeSelect
                  name="startDate"
                  control={control}
                  label="Start Time"
                />
                <Flex>
                  <Box w={8} ml={-8} mt={2}>
                    <Checkbox name="showEndTime" control={control} />
                  </Box>
                  <DatePickerField
                    showTimeSelect
                    name="endDate"
                    control={control}
                    label="End Time"
                    disabled={!showEndTime}
                  />
                </Flex>
                <TimeZoneSelect label="Time Zone" control={control} />
              </FormSection>
              <FormSection icon="event-where">
                <Heading size="sm" mt={2} mb={10}>Event Location</Heading>
                <PlacesAutocompleteField
                  name="venue"
                  control={control}
                  label="Venue"
                  onSelect={onPlacesSelect}
                />
                <AddressFields control={control} label="Address" eventAddress />
              </FormSection>
            </>
          )
        }}
      </ContainedForm>
    </>
  )
}

const FormSection = ({ icon, children }) => (
  <Flex w="80%" mt={5}>
    <Icon icon={icon} size={6} mr={3} mt={1} />
    <Box flex={1}>{children}</Box>
  </Flex>
)

const ImportExport = ({ onImport, onExport }) => (
  <>
    <SectionHeader>Import & Export</SectionHeader>
    <RoundedList>
      <RoundedListItem
        text1="Import Guests from a CSV or Spreadsheet File"
        text2={(
          <>
            For a step-by-step guide, see the{" "}
            <Link href={IMPORTING_GUEST_CSV_ARTICLE_LINK} isExternal>Help Center</Link>
            {" "}or{" "}
            <Link
              href={IMPORTING_GUEST_CSV_TEMPLATE_URL}
              isExternal
            >download csv template
            </Link>.
          </>
        )}
        action={<Button variant="outline" onClick={onImport}>Import CSV</Button>}
      />
      <RoundedListItem
        text1="Import Guests from Another Event"
        text2={(
          <>
            Import 1 or more guest lists in batch from another event. This feature is
            currently available on the <Link href={iOSAppURL} isExternal>Diobox iOS app</Link>.
          </>
        )}
      />
      <RoundedListItem
        text1="Import Contacts from Your iPhone or iPad"
        text2={(
          <>
            Use the import feature on the <Link href={iOSAppURL} isExternal>Diobox iOS app</Link>.
          </>
        )}
      />
      <RoundedListItem
        text1="Export Guest Data or Ticket Orders"
        text2="Multiple ways to export your event data."
        action={<Button variant="outline" onClick={onExport}>Export</Button>}
      />
    </RoundedList>
  </>
)

const EventControls = ({ eventName, onClone, handleDelete }) => (
  <>
    <SectionHeader>Event Controls</SectionHeader>
    <RoundedList>
      <RoundedListItem
        text1="Duplicate this event with all guests and guest lists"
        action={<Button variant="outline" onClick={onClone}>Clone Event</Button>}
      />
      <RoundedListItem
        text1="Permanently delete this event"
        action={(
          <ConfirmDeleteWithCheckboxes
            onSubmit={handleDelete}
            checkboxTexts={[
              "All guests will be removed",
              "All tickets and invitation records will be deleted",
              "All guest activity and analytics will be lost",
            ]}
            warningText="Warning: this cannot be undone."
            name={eventName}
            type="Event"
          >
            {({ setShowModal }) => (
              <Button onClick={() => setShowModal(true)} colorScheme="button.danger">
                Delete Event
              </Button>
            )}
          </ConfirmDeleteWithCheckboxes>
        )}
      />
    </RoundedList>
  </>
)

export default General
