import React, { useRef } from "react"
import Icon from "components/elements/Icon"
import Popover from "components/dialogs/Popover"
import {
  Box,
  Button as ChakraButton,
  Center, Flex, Heading, List, ListItem, Tag, Text, useDisclosure, useOutsideClick,
} from "@chakra-ui/react"
import { Guest, ModalName } from "sharedTypes"
import GuestAvatar from "components/elements/GuestAvatar"
import useModal from "services/useModal"
import Link from "components/elements/Link"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { linkGuest, unlinkGuest } from "api/guests"
import { useHistory, useParams } from "react-router-dom"
import { useQueryClient } from "react-query"
import { refetchGuests } from "context/actions"
import { eventGuestPath } from "utilities/routes"
import Confirm from "components/dialogs/Confirm/Confirm"
import { PLUS_ONE_ARTICLE_LINK } from "utilities/externalLinks"
import { sortPlusOnes } from "utilities/arrays"
import DropdownToggle from "../DropdownToggle"

type Props = {
  guest: Guest;
  enabled: boolean;
  disabled: boolean;
}

const Group = ({
  guest, guest: { group }, enabled, disabled,
}: Props) => {
  const { eventId } = useParams<{ eventId: string }>()
  const queryClient = useQueryClient()

  const ref = useRef<HTMLDivElement>(null)
  const { onOpen, onClose, isOpen } = useDisclosure()
  useOutsideClick({
    ref,
    handler: () => onClose(),
  })

  const toggleText = () => {
    const text = "Group"
    const count = group.length

    if (group.length > 1) {
      return text.concat(` of ${count}`)
    }

    return text
  }

  const [mainGuest, ...plusOnes] = group

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      isDisabled={disabled}
      arrow
      contentHeight="37.5rem"
      placement="auto"
      header="Group"
      trigger={(
        <DropdownToggle
          icon={<Icon icon="guest-group" size={6} mr={2} />}
          title={toggleText()}
          disabled={disabled}
        />
      )}
      closeOnBlur={false}
      body={(
        <Box ref={ref}>
          <Body
            queryClient={queryClient}
            eventId={eventId}
            mainGuest={mainGuest}
            plusOnes={plusOnes}
            guestId={guest.id}
            onClose={onClose}
          />
        </Box>
    )}
      footer={enabled ? (
        <Footer
          queryClient={queryClient}
          eventId={eventId}
          mainGuestId={mainGuest.id}
          mainGuestName={mainGuest.personInfo.firstName}
          plusOnesIds={plusOnes.map(({ id }) => id)}
        />
      ) : <UpgradeAlert text="This feature requires a Professional Subscription." />}
    />
  )
}

const Body = ({
  queryClient, eventId, mainGuest, plusOnes, guestId, onClose,
}) => {
  const history = useHistory()

  const handleSelectGuest = (id: string) => {
    history.push(eventGuestPath(eventId, id))
    onClose()
  }

  return (
    plusOnes.length
      ? (
        <List>
          {[
            mainGuest,
            ...sortPlusOnes(plusOnes),
          ].map(({
            id, personInfo, status, checkedIn, personInfo: { firstName, lastName },
          }, index) => {
            const isMain = index === 0

            return (
              <MenuItem
                onClick={() => handleSelectGuest(id)}
                icon={<GuestAvatar {...personInfo} status={status} checkedIn={!!checkedIn} />}
                selected={id === guestId}
              >
                <Text mr="auto" noOfLines={1} display="block">{firstName} {lastName}</Text>
                <Box flexShrink={0} w={16}>
                  {isMain
                    ? (
                      <Tag
                        variant="outline"
                        w={16}
                        fontSize="xs"
                        fontWeight="bold"
                        justifyContent="center"
                        boxShadow="none"
                        borderColor="gray.800"
                        borderWidth={1}
                        color="gray.800"
                      >
                        MAIN
                      </Tag>
                    )
                    : (
                      <Confirm
                        stopChildClickPropagation
                        confirmationTitle="Unlink Guest"
                        confirmationText={(
                          <>
                            <Text mb="4">Are you sure you want to unlink <Text as="strong">{firstName} {lastName}</Text> from this group.</Text>
                            <Text mb="4">
                              This will cause <Text as="strong">{firstName} {lastName}</Text> to become
                              independent and not attached to any other guests.
                            </Text>
                          </>
                          )}
                        confirmButtonLabel="Unlink"
                      >
                        <ChakraButton
                          w={16}
                          size="xs"
                          color="orange.400"
                          borderColor="orange.400"
                          variant="outline"
                          onClick={() => {
                            unlinkGuest(eventId, id).then(() => {
                              refetchGuests(queryClient, eventId)
                            })
                          }}
                        >
                          UNLINK
                        </ChakraButton>
                      </Confirm>
                    )}
                </Box>
              </MenuItem>
            )
          })}
        </List>
      )
      : (
        <Flex direction="column" align="center" p={6}>
          <Icon icon="guest-group" size={10} />
          <Text align="center">
            Groups allow you to link guests and their plus-ones to help you manage them together.
            <br />
            <Link target="_blank" href={PLUS_ONE_ARTICLE_LINK}>Learn More</Link>
          </Text>
        </Flex>
      )
  )
}

const Footer = ({
  queryClient, eventId, mainGuestId, mainGuestName, plusOnesIds,
}) => {
  const showModal = useModal()

  if (plusOnesIds.length >= 9) {
    return (
      <Box textAlign="center" p={4} color="gray.600">
        <Heading size="sm" fontWeight="semibold" mb={2}>Plus-One Limit Reached</Heading>
        <Text>Each guest group can have a max size of <Text as="strong">10</Text> total people.</Text>
      </Box>
    )
  }

  return (
    <List my={-2} mx={-3}>
      <ActionButton
        onClick={() => showModal(
          ModalName.SelectGuest,
          {
            onSelect: (selectedGuest) => {
              linkGuest(eventId, mainGuestId, selectedGuest.id).then(() => {
                refetchGuests(queryClient, eventId)
              })
            },
            showMenu: false,
            showTables: false,
            disableMainGuests: true,
            disabledGuestIds: [mainGuestId, ...plusOnesIds],
          },
        )}
        icon="external-link"
      >Link Existing Guest
      </ActionButton>
      <ActionButton
        onClick={() => showModal(
          ModalName.NewPlusOnes,
          {
            guestId: mainGuestId,
            plusOnesCount: plusOnesIds.length,
            guestName: mainGuestName,
          },
        )}
        icon="plus"
      >
        Create New Plus-Ones
      </ActionButton>
    </List>
  )
}

const ActionButton = ({ icon, onClick, children }) => (
  <MenuItem
    onClick={onClick}
    icon={(
      <Center display="inline-flex" w={10} h={10} bg="blue.400" borderRadius="full">
        <Icon icon={icon} size={4} color="white" />
      </Center>
)}
  >
    {children}
  </MenuItem>
)

const MenuItem = ({
  onClick, icon, children, selected = false,
}) => (
  <ListItem
    borderBottomWidth={1}
    _last={{ borderBottomWidth: 0 }}
  >
    {selected && <Icon icon="checkmark" position="absolute" mx={2} my={5} zIndex={1} color="gray.600" />}
    <ChakraButton
      variant="ghost"
      onClick={onClick}
      leftIcon={icon}
      textAlign="left"
      p={2}
      pl={7}
      h="auto"
      justifyContent="flex-start"
      w="full"
      borderRadius={0}
    >
      {children}
    </ChakraButton>
  </ListItem>
)

export default Group
