import React from "react"
import { Box, Text } from "@chakra-ui/react"
import LabelRedefinition from "modals/FormForm/LabelRedefinition"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { DioboxEvent, Translations } from "sharedTypes"
import TitleWithSubtitle from "../TitleWithSubtitle"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const FormButtonsSection = ({ event, updateEvent, defaultTranslations }: Props) => {
  const onSubmit = (values) => updateEvent(values)

  return (
    <Box>
      <TitleWithSubtitle
        title="Form Buttons"
        subtitle="These buttons appear throughout different sections of the Guest Portal."
        mb={5}
      />
      <ContainedForm values={event} onSubmit={onSubmit} name="Form Buttons">
        {({ control }) => (
          <Box maxWidth={400}>
            <Box mb={12}>
              <LabelRedefinition
                defaultTranslation={defaultTranslations.continue}
                name="translations.continue"
                control={control}
              />
              <Text>This button appears when there are multiple forms or
                tickets that the guest can fill out.
              </Text>
            </Box>
            <LabelRedefinition
              defaultTranslation={defaultTranslations.save}
              name="translations.save"
              control={control}
            />
            <Text>This button appears throughout the Guest Portal in all areas
              where the guest can save an existing form or their profile.
            </Text>
          </Box>
        )}
      </ContainedForm>
    </Box>
  )
}

export default FormButtonsSection
