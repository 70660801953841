import React from "react"
import { Text, Box, Stack } from "@chakra-ui/react"
import FormSection from "pages/EventShow/Forms/FormSection"
import { Form } from "sharedTypes"
import RSVPRadioButtons from "components/ReactHookForm/RSVPRadioButtonsField/RSVPRadioButtons"
import DynamicInputFields from "../DynamicInputFields"

type Props = {
  positiveAnswer: string
  negativeAnswer: string
  form: Form
  onClick?: () => void
  checkboxColor: string
}

const GuestNameBlock = ({
  positiveAnswer, negativeAnswer, form, onClick, checkboxColor,
}: Props) => (
  <Box borderBottomWidth={1}>
    <FormSection onEdit={onClick} first>
      <Stack spacing={5} my={1}>
        <Box textAlign="center">
          <Text fontSize="lg" fontWeight="semibold">Guest Name</Text>
          <Text>guest-email@example.com</Text>
        </Box>
        <RSVPRadioButtons
          defaultValue="positive"
          options={[{ label: positiveAnswer, value: "positive" }, { label: negativeAnswer, value: "negative" }]}
          name="attend"
          color={checkboxColor}
        />
      </Stack>
      <DynamicInputFields form={form} enableNameAndEmailToggle showTopDivider />
    </FormSection>
  </Box>
)

export default GuestNameBlock
