import React from "react"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import { Form, Translations } from "sharedTypes"
import EditorSection from "components/Builder/components/EditorSection"
import { Control } from "react-hook-form"
import { Text } from "@chakra-ui/react"
import MessagingSection from "../EditFormModalContent/EditFormModalSettings/Messaging"
import formatRegistrationFields from "../../RegistrationFields/formatRegistrationFields"
import RegistrationSettingsSwitches from "../../RegistrationFields/RegistrationSettingsSwitches"

type Props = {
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  form: Form
  control: Control
}

const MessagingSettings = ({
  updateEvent, defaultTranslations, form: { type, externalId }, form, control,
}: Props) => {
  const fields = formatRegistrationFields(form, true)

  return (
    <>
      <SidePanelHeader>Registration</SidePanelHeader>
      <MessagingSection
        updateEvent={updateEvent}
        defaultTranslations={defaultTranslations}
        formType={type}
        formId={externalId}
      />
      <EditorSection>
        <Text fontSize="lg" fontWeight="bold">Additional Registration Fields</Text>
        <Text mb={4}>You can allow guests to complete their profile by
          enabling additional fields.
        </Text>
        <Text mb={6}>If there is existing data, the fields will prepopulate, allowing
          the guest to edit their profile.
        </Text>
        <RegistrationSettingsSwitches fields={fields} control={control} />
      </EditorSection>
    </>
  )
}

export default MessagingSettings
