import {
  Box, Flex, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Text,
} from "@chakra-ui/react"
import GuestAvatar from "components/elements/GuestAvatar"
import React from "react"
import { firstName, lastName } from "utilities/strings"
import { useHistory } from "react-router"
import { eventGuestPath } from "utilities/routes"
import { useCurrentEvent } from "queries/events"
import { SubmissionResponseType } from "sharedTypes"
import Response from "./Response"

type Props = {
  guestId: string
  name: string
  photoUrl: string
  email: string
  response: SubmissionResponseType
}

const ResponseListItem = ({
  guestId, name, photoUrl, email, response,
}: Props) => {
  const history = useHistory()
  const { data: { id: eventId } } = useCurrentEvent()

  const handleClickGuest = () => history.push(eventGuestPath(eventId, guestId))

  return (
    <Box
      borderBottomWidth={1}
      borderColor="gray.300"
      bgColor="white"
      _last={{ borderBottomWidth: 0 }}
    >
      <Flex align="center" minH={20} px={3}>
        <Box w="50%">
          <Popover variant="responsive" placement="bottom">
            <PopoverTrigger>
              <Flex cursor="pointer" align="center">
                <Box mr={3}>
                  <GuestAvatar
                    firstName={firstName(name)}
                    lastName={lastName(name)}
                    photoUrl={photoUrl}
                  />
                </Box>
                <Box lineHeight="none" noOfLines={2} mr={3}>
                  <Text noOfLines={1}>{name}</Text>
                  <Text noOfLines={1}>{email}</Text>
                </Box>
              </Flex>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <Text fontWeight="semibold" cursor="pointer" onClick={handleClickGuest}>Guest Profile</Text>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
        <Box><Response response={response} /></Box>
      </Flex>
    </Box>
  )
}

export default ResponseListItem
