import React, { useEffect, useState } from "react"
import PaginatedView from "components/PaginatedView"
import { FormSubmissionSortOption } from "sharedTypes"
import { Box, Flex } from "@chakra-ui/react"
import MenuWithOptionGroups, { MenuWithOptionGroupType } from "components/MenuWithOptionGroups"
import Button from "components/Buttons/Button"
import { useForms } from "queries/forms"
import Spinner from "components/elements/Spinner"
import useSearchParams from "services/useSearchParams"
import { useEventFormSubmissionsPaginated } from "queries/formSubmissions"
import FormSubmissionListItem from "./FormSubmissionListItem"
import FilterPopover from "./FilterPopover"

type Props = {
  eventId: string
}

const FormSubmissionsListItems = ({ eventId }: Props) => {
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState<string|undefined>()
  const [filters, setFilters] = useState<string[]>([])
  const { isLoading: formsIsLoading, data: forms } = useForms(eventId)
  const { formId } = useSearchParams()

  const handleChangeSort = (selectedSortOption: string) => setSort(selectedSortOption)
  const handleResetFilters = () => setFilters([])

  useEffect(() => {
    if (formId) {
      setFilters([formId])
    }
  }, [])

  const {
    data,
    isPreviousData,
    isLoading: formSubmissionsIsLoading,
  } = useEventFormSubmissionsPaginated(
    eventId,
    page,
    sort as FormSubmissionSortOption,
    filters,
  )

  const viewButtonGroups = [{
    groupTitle: "Sort By",
    type: MenuWithOptionGroupType.Radio,
    defaultValue: FormSubmissionSortOption.CreatedAtDesc,
    onChange: handleChangeSort,
    options: [
      { title: "Date Created (Ascending)", value: FormSubmissionSortOption.CreatedAtAsc },
      { title: "Date Created (Descending)", value: FormSubmissionSortOption.CreatedAtDesc },
      { title: "Total Ticket Price (Ascending)", value: FormSubmissionSortOption.PriceAsc },
      { title: "Total Ticket Price (Descending)", value: FormSubmissionSortOption.PriceDesc },
      { title: "Name of Guest / Ticket Buyer (A-Z)", value: FormSubmissionSortOption.GuestNameAsc },
    ],
  }]

  if (formsIsLoading) {
    return <Spinner />
  }

  if (!forms) {
    return null
  }

  const header = (
    <Flex alignItems="center" justifyContent="space-between" minH={20} px={6} backgroundColor="white">
      <Flex>
        <MenuWithOptionGroups title="Sort" optionGroups={viewButtonGroups} />
        <Box ml={6}>
          <FilterPopover
            selectedFormIdFilters={filters}
            onSelectFormIdFilters={setFilters}
            forms={forms.data}
          />
        </Box>
      </Flex>
      {Boolean(filters.length) && (
        <Button variant="outline" colorScheme="button.light" size="sm" rightIcon="times" onClick={handleResetFilters}>
          Clear Filter
        </Button>
      )}
    </Flex>
  )

  const items = data?.data || []
  const totalCount = data?.meta.totalCount || 0

  return (
    <PaginatedView
      Component={FormSubmissionListItem}
      entityName="Item"
      borderWidth={0}
      additionalHeader={header}
      items={items}
      totalCount={totalCount}
      isPreviousData={isPreviousData}
      page={page}
      onChangePage={setPage}
      isLoading={formSubmissionsIsLoading}
    />
  )
}

export default FormSubmissionsListItems
