import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import backgroundImage from "assets/images/phone/frame.png"
import Header from "./Header"
import TextBubble from "./TextBubble"

type Props = {
  text: string
  phoneNumber?: string
}

const Phone = ({ text, phoneNumber }: Props) => (
  <>
    <Box
      position="absolute"
      backgroundColor="gray.100"
      h={40}
      overflow="auto"
      w={420}
      mt={1}
      ml={2}
      mr={2}
      borderTopRadius={60}
      borderBottomWidth={1}
    />
    <Flex
      w={430}
      h={530}
      bgImage={`url(${backgroundImage})`}
      backgroundSize="cover"
      position="relative"
      pt={7}
      flexDir="column"
    >
      <Header phoneNumber={phoneNumber} />
      <Box p={8}>
        <TextBubble text={text} />
      </Box>
    </Flex>
  </>
)

export default Phone
