import React, { useState, Dispatch } from "react"
import { useHistory, useParams } from "react-router-dom"
import Spinner from "components/elements/Spinner"
import Modal from "components/dialogs/Modal"
import { Flex, HStack, Text } from "@chakra-ui/react"
import FullScreenModalNav from "components/dialogs/FullScreenModalNav"
import Link from "components/elements/Link"
import Icon from "components/elements/Icon"
import { useForm } from "queries/forms"
import {
  BuilderBlockWithBlockId, DioboxEvent, Form, Translations,
} from "sharedTypes"
import { patchForm } from "api/forms"
import { updateForm } from "context/actions"
import { useQueryClient } from "react-query"
import { stripOutQuestionsFormBlockData } from "components/Builder/helpers"
import { useDeepCompareEffect } from "ahooks"
import { DraggableItem } from "sharedTypes/builder"
import { BlockInsertPosition } from "components/Builder/BuildingBlock"
import EditFormModalContent from "./EditFormModalContent/EditFormModalContent"

type Props = {
  blocks: BuilderBlockWithBlockId[];
  onBlockContentChange: (data: {}, selectedBlockId?: number) => void
  onDeleteBlock: () => void
  setSelectedBlockId: React.Dispatch<React.SetStateAction<number | undefined>>
  setBlocks: Dispatch<React.SetStateAction<BuilderBlockWithBlockId[]>>
  onDuplicateBlock: (blockId: number) => void
  selectedBlockId?: number
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  onDropBlock: (item: DraggableItem, blockId: number, position: BlockInsertPosition) => void
}
const EditForm = ({
  blocks,
  onBlockContentChange,
  setSelectedBlockId,
  selectedBlockId,
  setBlocks,
  onDeleteBlock,
  onDuplicateBlock,
  event,
  updateEvent,
  defaultTranslations,
  onDropBlock,
}: Props) => {
  const { eventId, formId } = useParams<{eventId: string, formId: string}>()
  const history = useHistory()
  const { isLoading, data: form } = useForm(eventId, formId)
  const [currentForm, setCurrentForm] = useState(form)
  const queryClient = useQueryClient()

  useDeepCompareEffect(() => {
    setCurrentForm(form)
  }, [form])

  if (isLoading) {
    return <Spinner heightFull />
  }

  if (!currentForm) {
    return null
  }

  const handleUpdateAttributes = (formData: Form) => {
    setCurrentForm(formData)
  }

  const handleSaveForm = () => {
    const formWithoutQuestionsFormBlockData = stripOutQuestionsFormBlockData({
      ...currentForm,
      formData: { blocks: blocks.map(({ block }) => block) },
    })

    patchForm(eventId, currentForm.externalId, formWithoutQuestionsFormBlockData)
      .then(({ data: updatedForm }) => {
        updateForm(queryClient, currentForm.externalId, updatedForm)
        history.go(-1)
      })
  }

  return (
    <Modal
      show
      size="full"
      hide={() => {}}
      header={(
        <FullScreenModalNav type="header">
          <Text w={1 / 3}>Customize Form</Text>
          <HStack spacing={4} w={1 / 3} justify="flex-end">
            <Link onClick={(handleSaveForm)} color="blue.300" fontWeight="bold">
              Save and Close
              <Icon icon="cancel-circle" ml={2} />
            </Link>
          </HStack>
        </FullScreenModalNav>
      )}
      noPadding
    >
      <Flex direction="column" h="full">
        <EditFormModalContent
          form={currentForm}
          setForm={setCurrentForm}
          blocks={blocks}
          onBlockContentChange={onBlockContentChange}
          onDeleteBlock={onDeleteBlock}
          setSelectedBlockId={setSelectedBlockId}
          selectedBlockId={selectedBlockId}
          setBlocks={setBlocks}
          onDuplicateBlock={onDuplicateBlock}
          onAttributeChange={handleUpdateAttributes}
          event={event}
          updateEvent={updateEvent}
          defaultTranslations={defaultTranslations}
          onDropBlock={onDropBlock}
        />
      </Flex>
    </Modal>
  )
}

export default EditForm
