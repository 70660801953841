import React from "react"
import EventResourcesView from "components/layout/EventResourcesView"
import NoContent from "components/elements/NoContent"
import { useParams } from "react-router"
import { DioboxEvent } from "sharedTypes"
import FormSubmissionsListItems from "./FormSubmissionsListItems"
import FormSubmissionsDetailView from "./FormSubmissionsDetailView/FormSubmissionsDetailView"

type Props = {
  event: DioboxEvent
}

const FormSubmissions = ({ event }: Props) => {
  const {
    id: eventId,
    currency,
    counts: { formSubmissions: formSubmissionCount },
  } = event

  const { submissionId } = useParams<{ submissionId: string }>()

  if (formSubmissionCount === 0) {
    return <NoContent icon="database-outline" title="Submissions" descriptionItems={["Form submissions and ticket orders will appear on this page."]} />
  }

  return (
    <EventResourcesView
      listWidth="xl"
      listItems={<FormSubmissionsListItems eventId={eventId} />}
      selectedItem={(
        <FormSubmissionsDetailView
          submissionId={submissionId}
          currency={currency}
          event={event}
        />
      )}
      showBorder={false}
    />
  )
}

export default FormSubmissions
