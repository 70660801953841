import React, { ReactNode } from "react"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import { currentUserData } from "services/authentication"
import ErrorView from "./ErrorView"

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY as string,
  releaseStage: import.meta.env.VITE_ENVIRONMENT,
  enabledReleaseStages: ["production", "staging"],
  plugins: [new BugsnagPluginReact()],
  appVersion: import.meta.env.DEPLOY_ID,
  onError: (e) => {
    const data = currentUserData()?.split(":")
    if (data) {
      e.setUser(data[0], data[1], data[2])
    }
  },
})

type Props = {
  children: ReactNode
}
const BugsnagErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary(React) || React.Fragment

const ErrorBoundary = ({ children }: Props) => (
  <BugsnagErrorBoundary FallbackComponent={ErrorView}>
    {children}
  </BugsnagErrorBoundary>
)

export default ErrorBoundary
