import React from "react"
import ButtonPreview, { DEFAULT_BUTTON_COLOR } from "components/elements/ButtonPreview"
import {
  Box, Flex, Heading, Text, VStack,
} from "@chakra-ui/react"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import TextField from "components/ReactHookForm/TextField"
import ColorSelect from "components/ReactHookForm/ColorSelect"

type Props = {
  color: string;
  text: string;
  updateWebsite: (params: {}) => Promise<void>;
}

const WebsiteButton = ({
  color, text, updateWebsite,
}: Props) => {
  const handleSubmit = updateWebsite

  return (
    <Box>
      <Heading mb={6} fontSize="lg">Button Style</Heading>
      <ContainedForm
        values={{
          rsvpButtonColor: color || DEFAULT_BUTTON_COLOR,
          actionText: text,
        }}
        onSubmit={handleSubmit}
      >
        {({ control, watch }) => {
          const { actionText, rsvpButtonColor } = watch()

          return (
            <VStack spacing={8} align="baseline">
              <TextField
                name="actionText"
                control={control}
                label="Button Text"
                size="lg"
              />
              <ColorSelect
                name="rsvpButtonColor"
                control={control}
                label="Button Color"
              />
              <Flex w="full" align="center">
                <Text as="strong" w={1 / 5} flexShrink={0}>Button Preview</Text>
                <ButtonPreview size="auto" color={rsvpButtonColor}>{actionText}</ButtonPreview>
              </Flex>
            </VStack>
          )
        }}
      </ContainedForm>
    </Box>
  )
}

export default WebsiteButton
