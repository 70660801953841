import { isPast } from "date-fns"
import {
  DioboxEvent, Form, FormStatus, FormType, Email, Subscription,
} from "../sharedTypes"

export const setValueIfNotEqual = (setValue, fieldName, previousValue, nextValue) => {
  if (previousValue !== nextValue) {
    setValue(fieldName, nextValue)
  }
}

export const getFormExternalUrl = (externalId: string, getPreview?: boolean) => `${import.meta.env.VITE_WEBSITE_HOST}/f/${externalId}${getPreview ? "/g/preview" : ""}`
export const getFormSubmissionExternalUrl = (id: string) => `${import.meta.env.VITE_WEBSITE_HOST}/i/${id}`
export const getFormSubmissionReceiptExternalUrl = (id: string) => `${import.meta.env.VITE_WEBSITE_HOST}/s/${id}`

export const formIconAndType = (formType: FormType): {
  icon: string, type: string
} => {
  const data = {
    icon: "", type: "", status: FormStatus.Published, color: "status.published",
  }

  switch (formType) {
    case FormType.Registration: {
      data.icon = "form-registration"
      data.type = "Registration"
      break
    }
    case FormType.Ticket: {
      data.icon = "form-ticket"
      data.type = "Ticket"
      break
    }
    case FormType.RSVP: {
      data.icon = "form-rsvp"
      data.type = "RSVP"
      break
    }
    case FormType.Waitlist: {
      data.icon = "form-waitlist"
      data.type = "Waitlist"
      break
    }
    default:
  }

  return data
}

export const formStatusLabel = (status: FormStatus, type: FormType): string => {
  switch (status) {
    case FormStatus.SoldOut: {
      switch (type) {
        case FormType.Waitlist:
          return "At Capacity"
        case FormType.Registration:
          return "At Capacity"
        default:
          return "Sold Out"
      }
    }
    case FormStatus.Ended:
      return "Ended"
    case FormStatus.Disabled:
      return "Disabled"
    case FormStatus.GatewayNotConnected:
      return "Gateway Not Connected"
    default:
      return "Published"
  }
}

export const formStatusAndColor = (
  form: Form,
  event: DioboxEvent,
): {status: FormStatus, color: string} => {
  const {
    disabled, submissionsEndAt, submissionLineItemsCount, quantity,
  } = form
  const { stripeAccountId } = event

  if (quantity && quantity <= submissionLineItemsCount) {
    return {
      status: FormStatus.SoldOut,
      color: "status.inactive",
    }
  }

  if (submissionsEndAt && isPast(new Date(submissionsEndAt))) {
    return {
      status: FormStatus.Ended,
      color: "status.ended",
    }
  }

  if (disabled) {
    return {
      status: FormStatus.Disabled,
      color: "status.disabled",
    }
  }

  if (!stripeAccountId && form.type === FormType.Ticket) {
    return {
      status: FormStatus.GatewayNotConnected,
      color: "status.inactive",
    }
  }

  return {
    status: FormStatus.Published,
    color: "status.published",
  }
}

export const getEnabledFormTypesBySubscriptionLevel = (subscription: Subscription | null) => {
  const privateRSVPEnabled = subscription && ["Professional", "Corporate"].includes(subscription.type)
  const waitlistEnabled = subscription && ["Corporate"].includes(subscription.type)

  return [
    FormType.Registration,
    FormType.Ticket,
    ...(privateRSVPEnabled ? [FormType.RSVP] : []),
    ...(waitlistEnabled ? [FormType.Waitlist] : []),
  ]
}

export const submissionIsPaid = (price: string | null) => price && parseFloat(price) > 0

export const getFormsByType = (forms: Form[], type: FormType) => forms.filter(
  (form: Form) => form.type === type,
)

export const getFormsByTypeWithVisibilityAndStatus = (
  forms: Form[],
  formType: FormType,
  event: DioboxEvent,
) => getFormsByType(
  forms, formType,
).map((form) => {
  const { name, externalId, type } = form
  const { status } = formStatusAndColor(form, event)

  const label = `${name}${status !== FormStatus.Published ? ` (${formStatusLabel(status, type)})` : ""}`

  return { label, value: externalId, disabled: status === FormStatus.Disabled }
})

export const emailIcon = (email: Email) => (email.hasForm ? "email-with-form" : "email-plain")

export const requiresUpgrade = (integratedForms: Form[], subscriptionType: Subscription | null) => {
  const enabledFormTypes = getEnabledFormTypesBySubscriptionLevel(subscriptionType)

  return integratedForms.some(({ type }) => !enabledFormTypes.includes(type))
}

export const getFormsGroupedByType = (forms: Form[], event: DioboxEvent) => [
  {
    label: "Registration Forms",
    options: getFormsByTypeWithVisibilityAndStatus(forms, FormType.Registration, event),
  },
  {
    label: "Ticket Forms",
    options: getFormsByTypeWithVisibilityAndStatus(forms, FormType.Ticket, event),
  },
  {
    label: "RSVP Forms",
    options: getFormsByTypeWithVisibilityAndStatus(forms, FormType.RSVP, event),
  },
  {
    label: "Waitlist Forms",
    options: getFormsByTypeWithVisibilityAndStatus(forms, FormType.Waitlist, event),
  },
].filter((group) => Boolean(group.options.length))
