import { Box, Flex, ListItem } from "@chakra-ui/react"
import React, { ReactNode, useRef } from "react"
import { ConnectDragPreview, useDrag, useDrop } from "react-dnd"
import Icon from "components/elements/Icon"
import { ItemTypes } from "./index"

type Props = {
  dragId: string;
  children: ReactNode | ((ref: ConnectDragPreview) => ReactNode);
  onMove: (fromId: string, toId: string) => void;
  onDrop: () => void;
  editing: boolean;
  number?: number;
  onDelete?: () => void;
  show?: boolean;
  draggable?: boolean;
}

type DraggableItem = {
  dragId: string;
  type: ItemTypes;
}

const Item = ({
  dragId, children, onMove, onDrop, onDelete, editing, number, show = true, draggable = true,
}: Props) => {
  const ref = useRef(null)

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.LIST_ITEM,
    canDrag: editing,
    item: { dragId, type: ItemTypes.LIST_ITEM },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [/* collectedProps */, drop] = useDrop({
    accept: ItemTypes.LIST_ITEM,
    hover: (item: DraggableItem) => {
      if (item.dragId !== dragId) {
        onMove(item.dragId, dragId)
      }
    },
    drop: () => {
      onDrop()
    },
  })

  drop(ref)
  if (draggable) {
    drag(ref)
  }

  return (
    <ListItem
      ref={ref}
      opacity={isDragging ? 0.5 : 1}
      cursor={editing ? "move" : ""}
    >
      {
        show && (
          <Flex align="center" fontSize="lg">
            <Box w={10}>
              {
                (editing && onDelete)
                && (
                  <button type="button" onClick={onDelete} title="Remove">
                    <Icon icon="cross" size={5} color="red.500" />
                  </button>
                )
              }
            </Box>
            <Box w={7} fontWeight="semibold">{number! > 0 ? number : null}</Box>
            <Flex
              align="center"
              justify="space-between"
              flexGrow={1}
              h={12}
              borderBottomWidth={1}
            >
              {typeof children === "function" ? children(preview) : children}
              {editing && <Icon icon="menu" color="gray.300" cursor="move" />}
            </Flex>
          </Flex>
        )
      }
    </ListItem>
  )
}

Item.defaultProps = {
  onDelete: null,
  show: true,
  number: -1,
}

export default Item
