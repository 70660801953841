import React from "react"
import { Control } from "react-hook-form"
import { Box } from "@chakra-ui/react"
import BasicInputs from "./BasicInputs"
import DropdownSelect from "./DropdownSelect"
import FormDetailHeader from "./FormDetailHeader"

type Props = {
  control: Control<object, any>
  name: string
}

const PublicRSVP = ({ control, name }: Props) => (
  <Box pb={4}>
    <FormDetailHeader
      name={name}
      description="This form allows anyone to register for your event."
    />
    <BasicInputs control={control} showVisibilityInput showQuantityInput>
      <DropdownSelect
        name="limitPerSubmission"
        control={control}
        label="Max per Submission"
      />
    </BasicInputs>
  </Box>
)

export default PublicRSVP
