import React from "react"
import { Control } from "react-hook-form"
import PriceWithRadio from "modals/FormForm/PaidTicket/PriceWithRadio"
import { Box } from "@chakra-ui/react"
import { CURRENCY_OPTIONS } from "utilities/enums"
import FormDetailHeader from "../FormDetailHeader"
import BasicInputs from "../BasicInputs"
import DropdownSelect from "../DropdownSelect"

type Props = {
  control: Control<object, any>
  name: string
  currency: string
}

const PaidTicket = ({ control, name, currency }: Props) => {
  const currencyName = CURRENCY_OPTIONS.find(
    (currencyOption) => currencyOption.value === currency,
  )?.label

  return (
    <Box pb={6}>
      <FormDetailHeader
        name={name}
        description="This form enables you to sell tickets. It supports a simple checkout experience
    by the guest or through the point-of-sale integration on the Diobox mobile app."
      />
      <BasicInputs control={control} showVisibilityInput showQuantityInput>
        <DropdownSelect
          name="limitPerSubmission"
          control={control}
          label="Max per Order"
        />
        <PriceWithRadio
          control={control}
          enabledLabel="Regular Pricing"
          disabledLabel="Variable / Donation"
          w={40}
          rightText={currencyName}
        />
      </BasicInputs>
    </Box>
  )
}

export default PaidTicket
