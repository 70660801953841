import { Stack } from "@chakra-ui/react"
import React, { ReactNode } from "react"

type Props = {
  children: ReactNode
  absolute?: boolean
}

const AlertBoxWrapper = ({ children, absolute }: Props) => (
  <Stack position={absolute ? "absolute" : "static"} m={5} w="full" alignItems="center" gap={4}>
    {children}
  </Stack>
)

export default AlertBoxWrapper
