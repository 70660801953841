import React, { ReactNode, useState } from "react"
import { useHistory } from "react-router-dom"
import { eventPath } from "utilities/routes"
import * as Yup from "yup"
import { postEvent } from "api/events"
import Button from "components/Buttons/Button"
import { Box, FormControl, FormLabel } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { addMonths } from "date-fns"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import DatePickerField from "components/ReactHookForm/DatePickerField"
import PlacesAutocompleteField from "components/ReactHookForm/PlacesAutocompleteField"
import TimeZoneSelect from "components/ReactHookForm/TimeZoneSelect"
import Checkbox from "components/ReactHookForm/Checkbox"
import AddressFields from "components/ReactHookForm/AddressFields"
import _ from "lodash"

type Props = {
  button?: ReactNode
}

const NewEvent = ({ button }: Props) => {
  const [show, setShow] = useState(false)
  const history = useHistory()

  const onSubmit = (values) => postEvent(values.showEndTime ? values : _.omit(values, "endDate")).then((response) => {
    history.push(eventPath(response.data.id))
  })

  const EventSchema = Yup.object().shape({
    title: Yup.string().required(),
    startDate: Yup.date().required(),
    showEndTime: Yup.boolean(),
    endDate: Yup.date().when("showEndTime", {
      is: true,
      then: (schema) => schema.min(Yup.ref("startDate")),
    }),
  })

  return (
    <>
      {button
        ? (
          <button onClick={() => setShow(true)}>
            {button}
          </button>
        )
        : (
          <Button
            leftIcon="plus"
            variant="solid"
            size="sm"
            onClick={() => setShow(true)}
          >Event
          </Button>
        )}
      { show && (
      <ModalForm
        initialValues={{
          title: "",
          startDate: new Date(addMonths(new Date(), 1).setHours(19, 0, 0)),
          showEndTime: false,
          endDate: new Date(addMonths(new Date(), 1).setHours(20, 0, 0)),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }}
        validationSchema={EventSchema}
        onSubmit={onSubmit}
        onClose={() => {
          setShow(false)
        }}
        title="New Event"
        size="xl"
      >
        {({ control, watch, setValue }) => {
          const [showEndTime, startDate] = watch(["showEndTime", "startDate"])

          return (
            <Form
              control={control}
              showEndTime={showEndTime}
              setValue={setValue}
              startDate={startDate}
            />
          )
        }}
      </ModalForm>
      ) }
    </>
  )
}
const Form = ({
  control, setValue, showEndTime, startDate,
}) => {
  const onPlacesSelect = (addressComponents) => {
    [
      "venue",
      "addressLine1",
      "city",
      "state",
      "zipcode",
      "country",
    ].forEach((fieldName) => {
      setValue(fieldName, addressComponents[fieldName])
    })
  }

  return (
    <>
      <Fieldset>
        <TextField name="title" control={control} placeholder="Event Title" size="2xl" />
      </Fieldset>
      <Fieldset>
        <FormLabel as="legend" fontWeight="bold">
          <Icon icon="dash-calendar" size={6} mr={2} /> Date & Time
        </FormLabel>
        <Box ml={8}>
          <DatePickerField showTimeSelect name="startDate" control={control} variant="outline" />
          <Box mb={-3}><Checkbox name="showEndTime" control={control} label="Show End Time" /></Box>
          <DatePickerField showTimeSelect name="endDate" control={control} variant="outline" disabled={!showEndTime} minDate={new Date(startDate)} />
          <TimeZoneSelect control={control} />
        </Box>
      </Fieldset>
      <Fieldset>
        <FormLabel as="legend" fontWeight="bold">
          <Icon icon="event-where" size={6} mr={2} /> Location
        </FormLabel>
        <Box ml={8}>
          <PlacesAutocompleteField name="venue" control={control} placeholder="Venue" onSelect={onPlacesSelect} />
          <AddressFields control={control} eventAddress />
        </Box>
      </Fieldset>
    </>
  )
}

const Fieldset = ({ children }: {children: ReactNode | ReactNode[]}) => (
  <FormControl as="fieldset" mb={8}>{children}</FormControl>
)

export default NewEvent
