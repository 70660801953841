import React, { ReactNode, useEffect, useRef } from "react"
import { Flex, Box, List } from "@chakra-ui/react"
import _ from "lodash"
import { SIDEBAR_WIDTHS } from "utilities/enums"
import SimpleBar from "simplebar-react"
import PageContent from "./PageContent"
import ScrollToTop from "../Navigation/ScrollToTop"

interface EventResourcesViewProps {
  listWidth?: "sm" | "md" | "lg" | "xl"
  listHeader?: ReactNode
  listItems: ReactNode | ReactNode[]
  selectedItem: ReactNode
  onScrollEnd?: () => void
  onContentScrollEnd?: () => void
  bgColor?: string
  showBorder?: boolean
}

const EventResourcesView = ({
  listWidth,
  listHeader,
  listItems,
  selectedItem,
  onScrollEnd,
  onContentScrollEnd,
  bgColor,
  showBorder = true,
}: EventResourcesViewProps) => (
  <Flex flexGrow={1} position="relative" h="100%">
    <Flex
      w={SIDEBAR_WIDTHS[listWidth!]}
      minWidth={300}
      maxWidth={500}
      borderRightWidth={1}
      direction="column"
      flexShrink={0}
    >
      {listHeader}
      <ScrollView listItems={listItems} onScrollEnd={onScrollEnd} />
    </Flex>
    <Flex
      direction="column"
      flexGrow={1}
    >
      <SelectedItem
        selectedItem={selectedItem}
        onContentScrollEnd={onContentScrollEnd}
        showBorder={showBorder}
        bgColor={bgColor}
      />
    </Flex>
  </Flex>
)

const ScrollView = ({ listItems, onScrollEnd }) => {
  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
      onScrollEnd!()
    }
  }

  const debouncedHandleScroll = _.debounce(handleScroll, 100)

  return (
    <Box
      as={SimpleBar}
      h={1}
      flexGrow={1}
      scrollableNodeProps={{
        onScroll: (e) => {
          if (onScrollEnd) {
            e.persist()
            debouncedHandleScroll(e)
          }
        },
      }}
    >
      <List>
        {listItems}
      </List>
    </Box>
  )
}

const SelectedItem = ({
  selectedItem, onContentScrollEnd, showBorder, bgColor,
}) => {
  const ref = useRef(null)
  const scrollableNodeRef = useRef(null)

  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
      onContentScrollEnd!()
    }
  }

  const debouncedHandleScroll = _.debounce(handleScroll, 100)

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      ref.current.contentEl.style.height = "100%"
    }
  }, [ref])

  return (
    <Box
      position="relative"
      overflowX="hidden"
      as={SimpleBar}
      h={1}
      flexGrow={1}
      ref={ref}
      scrollableNodeProps={{
        onScroll: (e) => {
          if (onContentScrollEnd) {
            e.persist()
            debouncedHandleScroll(e)
          }
        },
        ref: scrollableNodeRef,
      }}
      background={bgColor || (showBorder ? "gray.50" : "white")}
    >
      <PageContent showBorder={showBorder}>{selectedItem}</PageContent>
      <ScrollToTop containerRef={scrollableNodeRef} />
    </Box>
  )
}

EventResourcesView.defaultProps = {
  listWidth: "lg",
}

export default EventResourcesView
