import React, { useState } from "react"
import { Box, Flex } from "@chakra-ui/react"
import { Route, useHistory } from "react-router"
import { useForm } from "queries/forms"
import Spinner from "components/elements/Spinner"
import { useQueryClient } from "react-query"
import { refetchEventQuestions } from "context/actions"
import { eventEditFormPath } from "utilities/routes"
import {
  BuilderBlockWithBlockId, FormQuestion, DioboxEvent, Translations, ModalName,
} from "sharedTypes"
import { useDeepCompareEffect } from "ahooks"
import { handleAddBlock, handleBlockContentChange, handleDeleteBlock } from "components/Builder/helpers"
import { BuilderBlock, BuilderBlockType, DraggableItem } from "sharedTypes/builder"
import { postEventQuestion } from "api/forms"
import { getEnabledFormTypesBySubscriptionLevel } from "utilities/forms"
import useCurrentAccount from "services/useCurrentAccount"
import { BlockInsertPosition } from "components/Builder/BuildingBlock"
import useModal from "services/useModal"
import StatusBox from "./StatusBox/StatusBox"
import FormContainer from "./FormContainer"
import EditForm from "./EditForm/EditForm"
import FormUpgradeBox from "./FormUpgradeBox"
import FormOptions from "./FormOptions"

type Props = {
  formId: string
  path: string
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
  formsCount: number
}

const SelectedForm = ({
  formId, path, event, updateEvent, defaultTranslations, formsCount,
}: Props) => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const { id: eventId, currency } = event
  const { isLoading, data: currentForm } = useForm(eventId, formId)
  const [blocks, setBlocks] = useState([] as BuilderBlockWithBlockId[])
  const [selectedBlockId, setSelectedBlockId] = useState<number>()
  const { subscription } = useCurrentAccount()
  const showModal = useModal()

  useDeepCompareEffect(() => {
    if (!currentForm) {
      return
    }
    setBlocks(currentForm.formData.blocks.map(
      (block, index) => (
        { block, blockId: index } as BuilderBlockWithBlockId),
    ))
  }, [currentForm])

  const handleAddFormQuestionBlock = (
    blockToAdd: BuilderBlock,
    position: BlockInsertPosition,
    blockId?: number,
  ) => {
    handleAddBlock({
      type: BuilderBlockType.QUESTION,
      setBlocks,
      setSelectedBlockId,
      position,
      blockId,
      block: blockToAdd,
    })
  }

  const handleDuplicateBlock = async (blockId: number) => {
    const blockToDuplicate = blocks.find((block) => block.blockId === blockId)

    if (!blockToDuplicate) {
      return
    }

    let blockDataToDuplicate = blockToDuplicate.block

    if (blockToDuplicate.block.type === BuilderBlockType.QUESTION) {
      await postEventQuestion(event.id, blockToDuplicate.block.data as FormQuestion)
        .then(async (data) => {
          blockDataToDuplicate = { type: BuilderBlockType.QUESTION, data: data.data }
          await refetchEventQuestions(queryClient, event.id)
        })
    }

    handleAddBlock({
      type: blockToDuplicate.block.type,
      setBlocks,
      setSelectedBlockId,
      position: BlockInsertPosition.Bottom,
      blockId,
      block: blockDataToDuplicate,
    })
  }

  const handleEditForm = () => {
    history.push(eventEditFormPath(event.id, formId))
  }

  const handleDropBlock = (
    item: DraggableItem,
    blockId: number,
    position: BlockInsertPosition,
  ) => {
    if (item.blockTypeToAdd === BuilderBlockType.QUESTION) {
      showModal(
        ModalName.AddFormQuestion, {
          blocks,
          addBlock: handleAddFormQuestionBlock,
          selectedQuestionId: blockId,
          position,
        },
      )
    } else if (item.blockTypeToAdd) {
      handleAddBlock({
        type: item.blockTypeToAdd,
        setBlocks,
        setSelectedBlockId,
        position,
        blockId,
      })
    }
  }

  if (isLoading) {
    return <Spinner heightFull />
  }

  if (!currentForm) {
    return null
  }

  const enabledFormTypes = getEnabledFormTypesBySubscriptionLevel(subscription)

  const formTypeDisabled = !enabledFormTypes.includes(currentForm.type)

  return (
    <>
      <Flex flexDir="column" alignItems="flex-end" gap={6}>
        <FormOptions
          onClickEditForm={handleEditForm}
          form={currentForm}
          formsCount={formsCount}
          formTypeDisabled={formTypeDisabled}
          event={event}
          updateEvent={updateEvent}
          defaultTranslations={defaultTranslations}
        />
        {formTypeDisabled
          ? <FormUpgradeBox formType={currentForm.type} />
          : <StatusBox form={currentForm} event={event} />}
      </Flex>
      <Box pb={10} w={480} margin="auto">
        <FormContainer
          form={currentForm}
          blocks={blocks}
          setBlocks={setBlocks}
          onDeleteBlock={() => handleDeleteBlock(setBlocks, selectedBlockId)}
          onDuplicateBlock={handleDuplicateBlock}
          currency={currency}
        />
      </Box>
      <Route path={`${path}/edit`}>
        <EditForm
          blocks={blocks}
          onBlockContentChange={
            (data) => handleBlockContentChange(data, setBlocks, selectedBlockId)
          }
          selectedBlockId={selectedBlockId}
          setSelectedBlockId={setSelectedBlockId}
          setBlocks={setBlocks}
          onDeleteBlock={() => handleDeleteBlock(setBlocks, selectedBlockId)}
          onDuplicateBlock={handleDuplicateBlock}
          event={event}
          updateEvent={updateEvent}
          defaultTranslations={defaultTranslations}
          onDropBlock={handleDropBlock}
        />
      </Route>
    </>
  )
}

export default SelectedForm
