import React from "react"
import { ChakraProps, Flex, Text } from "@chakra-ui/react"

type Props = {
  label: string;
  width?: string;
  color?: string;
  textColor?: string;
} & ChakraProps

const Pill = ({
  label, color, textColor = "white", ...props
}: Props) => (
  <Flex bgColor={color} textAlign="center" borderRadius="base" h="24px" align="center" justify="center" shrink={0} {...props}>
    <Text fontSize="xs" fontWeight="semibold" color={textColor} casing="uppercase">{label}</Text>
  </Flex>
)

Pill.defaultProps = {
  color: "gray.200",
  width: "16",
}

export default Pill
