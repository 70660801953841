import React from "react"
import {
  Box,
  FormControl, FormHelperText, FormLabel, Textarea as ChakraTextArea,
} from "@chakra-ui/react"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  label?: string;
  placeholder?: string;
  vertical?: boolean;
  helperText?: string;
  h?: string | number;
}

const TextArea = ({
  name, control, label, placeholder, vertical, helperText, h,
}: Props) => {
  const { field } = useController({ name, control })

  return (
    <FormControl mb={4} display={vertical ? "initial" : "flex"}>
      {label && <FormLabel fontWeight="bold" w={vertical ? "auto" : 1 / 4}>{label}</FormLabel>}
      <Box w="100%">
        <ChakraTextArea
          {...field}
          placeholder={placeholder}
          bg="white"
          h={h}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Box>
    </FormControl>
  )
}

TextArea.defaultProps = {
  placeholder: null,
  vertical: false,
}

export default TextArea
