import React from "react"
import Icon from "components/elements/Icon"
import { Transaction } from "sharedTypes"
import { Link } from "react-router-dom"
import { eventGuestPath } from "utilities/routes"
import { Link as ChakraLink, Text } from "@chakra-ui/react"

const icons = {
  AttendanceTransaction: {
    name: "check-in",
    color: "green.500",
  },
  Ticket: {
    name: "ticket",
    color: "gray.500",
  },
  PaidTicket: {
    name: "ticket",
    color: "green.500",
  },
  RatingTransaction: {
    name: "rating",
    color: "gray.500",
  },
  TravelPlansTransaction: {
    name: "travel",
    color: "gray.500",
  },
  AccommodationTransaction: {
    name: "accommodation",
    color: "gray.500",
  },
  PackageTransaction: {
    name: "package",
    color: "gray.500",
  },
  NoteTransaction: {
    name: "note",
    color: "gray.500",
  },
}

type Props = {
  item: Transaction
}

const GeneralItem = ({
  item: {
    id, guestId, eventId, type, displayType, title, data, details,
  },
}: Props) => (
  <>
    <ChakraLink as={Link} to={`${eventGuestPath(eventId, guestId)}/activities/${id}/edit`} mb={3}>
      <Icon
        size={6}
        icon={icons[type]?.name || "globe"}
        mr={2}
        color={icons[type]?.color || "gray.500"}
      /><Text as="span" color="gray.900">{displayType}</Text>
    </ChakraLink>
    {title && <div>{ title }</div>}
    {data?.type && <div>{ data.type }</div>}
    {details && <div>{ details }</div>}
  </>
)

export default GeneralItem
