import React from "react"
import { Control } from "react-hook-form"
import {
  Box, Center, Stack, Text,
} from "@chakra-ui/react"
import RSVPRadioButtonsField from "components/ReactHookForm/RSVPRadioButtonsField/RSVPRadioButtonsField"
import { SubmissionGuest, Translations } from "sharedTypes"

type Props = {
  control: Control<{
    guest: {
        status: string;
    };
  }, any>
  guest: Partial<SubmissionGuest>
  namePrefix: string
  translations: Translations
  primaryColor: string
  disabled?: boolean
}
export const RSVPFields = ({
  control,
  guest,
  namePrefix,
  translations,
  primaryColor,
  disabled = false,
}: Props) => (
  <Stack py={5} spacing={5}>
    <Box textAlign="center">
      <Text fontSize="lg" fontWeight="semibold">
        {guest.firstName} {guest.lastName}
      </Text>
      <Text>{guest.email}</Text>
    </Box>
    <Center pointerEvents={disabled ? "none" : "auto"}>
      <RSVPRadioButtonsField
        control={control}
        name={`${namePrefix}.status`}
        options={[
          {
            label: <Text>{translations.rsvpConfirm}</Text>,
            value: "1",
          },
          {
            label: <Text>{translations.rsvpDecline}</Text>,
            value: "2",
          },
        ]}
        primaryColor={primaryColor}
      />
    </Center>
  </Stack>
)

export default RSVPFields
