import React from "react"
import { useHistory } from "react-router-dom"
import { eventGuestPath } from "utilities/routes"
import Checkboxes from "components/ReactHookForm/Checkboxes"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import * as eventsApi from "api/events"
import { updateEventGroup, updateGuest } from "context/actions"
import { EventGroup, Guest, AccountUser } from "sharedTypes"
import { Text } from "@chakra-ui/react"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { useCurrentEvent } from "queries/events"
import { useQueryClient } from "react-query"
import useCurrentAccount from "services/useCurrentAccount"
import { putGuest } from "api/guests"
import { eventAndAccountAdmins } from "utilities/objects"
import UserListItem from "./UserListItem"

type Props = {
  teamMembers: AccountUser[]
  guest?: Guest
  guestList?: EventGroup
  onClose?: () => void
}

const CheckInAlert = ({
  teamMembers, guest, guestList, onClose,
}: Props) => {
  const queryClient = useQueryClient()
  const { subscription } = useCurrentAccount()
  const { data: currentEvent } = useCurrentEvent()
  const history = useHistory()

  const {
    users = [],
  } = currentEvent

  let description
  let handleClose
  let notifications
  let onSubmit

  if (guestList) {
    handleClose = onClose
    description = <>anyone on the <Text as="strong" fontWeight="bold">&ldquo;{guestList.title}&rdquo;</Text> guest list checks-in</>
    notifications = guestList.notifications || []
    onSubmit = ({ users: userIds }) => eventsApi.putEventGroup(
      currentEvent.id,
      {
        ...guestList,
        notifications: userIds.map((id) => ({ userId: id, email: true })),
      },
    ).then(({ data }) => {
      updateEventGroup(queryClient, currentEvent.id, data)
      handleClose()
    })
  } else if (guest) {
    const {
      firstName,
      lastName,
    } = guest.personInfo

    handleClose = () => history.push(eventGuestPath(currentEvent.id, guest.id))
    description = <>&ldquo;<Text as="strong" fontWeight="bold">{firstName} {lastName}</Text>&rdquo; or any plus-ones check-in</>
    notifications = guest.notifications || []
    onSubmit = ({ users: userIds }) => putGuest(
      currentEvent.id,
      guest.id,
      {
        ...guest,
        notifications: userIds.map((id) => ({ userId: id, email: true })),
      },
    ).then(({ data }) => {
      updateGuest(queryClient, data)
      handleClose()
    })
  }

  const allUsers = eventAndAccountAdmins(users, teamMembers)

  const initialValues = {
    users: notifications.map(({ userId }) => userId),
  }

  return (
    <ModalForm
      title="Check-In Alert"
      onSubmit={onSubmit}
      initialValues={initialValues}
      onClose={handleClose}
      hideActions={!subscription}
    >
      {({ control }) => (
        <>
          <Text mb={6}>
            Send an email alert when {description}.
          </Text>
          {subscription
              && (
                <Checkboxes
                  name="users"
                  control={control}
                  items={allUsers.filter((u) => u.user?.confirmed).map(({ user }) => user)}
                  itemComponent={UserListItem}
                />
              )}
          {!subscription
              && <UpgradeAlert text="This feature requires a Basic Subscription." />}

        </>
      )}
    </ModalForm>
  )
}

export default CheckInAlert
