import React from "react"
import {
  Heading, Select, Text, Box, Grid, GridItem, Stack,
  Flex,
} from "@chakra-ui/react"
import FormSection from "pages/EventShow/Forms/FormSection"
import { Form } from "sharedTypes"
import InputWithLabel from "./InputWithLabel"
import FormHeader from "../FormHeader"

type Props = {
  label: string;
  form: Form
}

const FormPlusOnes = ({ label, form }: Props) => {
  const { maxPlusOnes, plusOnesEnabled, translations: { rsvpPlusOneYes, rsvpPlusOneNo } } = form

  if (!plusOnesEnabled) {
    return null
  }

  return (
    <>
      <FormHeader icon="guest-group" isSeparate={false}>
        <Text fontSize="2xl" fontWeight="semibold">Plus-Ones</Text>
      </FormHeader>
      <Box borderWidth={1}>
        <FormSection>
          <Stack spacing={8} px={6}>
            <Flex align="center" gap={4}>
              <Heading size="sm" fontWeight="semibold" color="gray.700" flexShrink={0}>{label}</Heading>
              <Select value={maxPlusOnes} w="max-content">
                <option value="">{rsvpPlusOneYes}</option>
                <option value="">{rsvpPlusOneNo}</option>
              </Select>
            </Flex>
            <Grid
              templateColumns="2rem 23% 23% auto"
              columnGap={3}
              rowGap={8}
            >
              {[...Array(maxPlusOnes)].map((n, i) => (
                <>
                  <GridItem
                    display="flex"
                    justifyContent="left"
                    alignItems="flex-end"
                    mb={2}
                  >
                    <Text fontWeight="semibold" color="gray.700">#{i + 1}</Text>
                  </GridItem>
                  <GridItem><InputWithLabel label="First Name" required /></GridItem>
                  <GridItem><InputWithLabel label="Last Name" required /></GridItem>
                  <GridItem><InputWithLabel label="Email Address" /></GridItem>
                </>
              ))}
            </Grid>
          </Stack>
        </FormSection>
      </Box>
    </>
  )
}

export default FormPlusOnes
