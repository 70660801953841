import React from "react"
import {
  Box, Flex, HStack, Stack, Text,
} from "@chakra-ui/react"
import { Translations } from "sharedTypes"
import SubmissionCard from "pages/EventShow/Submissions/FormSubmissions/FormSubmissionsDetailView/SubmissionCard"
import Icon from "components/elements/Icon"

const PlusOnes = ({
  translations,
  primaryColor,
  plusOnes,
}: {
  translations: Translations
  primaryColor: string
  plusOnes: { id: string; name: string; email: string }[]
}) => (
  <SubmissionCard
    header={(
      <Flex
        justify="space-between"
        fontSize="xl"
        fontWeight="semibold"
        w="100%"
      >
        <HStack fontWeight="semibold" fontSize="xl">
          <Icon icon="guest-group" size={6} mr={1} />
          <Text>Plus-Ones</Text>
        </HStack>
      </Flex>
      )}
  >
    <Show
      plusOnes={plusOnes}
      translations={translations}
      primaryColor={primaryColor}
    />
  </SubmissionCard>
)

const Show = ({
  plusOnes,
  translations,
  primaryColor,
}: {
  primaryColor: string
  plusOnes: { id: string; name: string; email: string }[]
  translations: Translations
}) => (
  <>
    <Box mb={plusOnes.length > 0 ? 6 : 0} fontWeight="semibold">
      <Text>{translations.rsvpPlusOneLabel}</Text>
      <Text color={primaryColor}>
        {plusOnes.length === 0
          ? translations.rsvpPlusOneNo
          : `${translations.rsvpPlusOneYes} (+${plusOnes.length})`}
      </Text>
    </Box>
    <Stack spacing={4}>
      {plusOnes.map((plusOne, index) => (
        <Flex key={index} justify="space-between">
          <Flex>
            <Text w="48px" mr={3} fontSize="xl" fontWeight="semibold">
              #{index + 1}
            </Text>
            <Box>
              <Text>{plusOne.name}</Text>
              <Text>{plusOne.email}</Text>
            </Box>
          </Flex>
          <Flex align="center">
            <Text mr={2}>Confirmation Page</Text>
            <Icon icon="link-external" />
          </Flex>
        </Flex>
      ))}
    </Stack>
  </>
)

export default PlusOnes
