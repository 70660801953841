import { useForms } from "queries/forms"
import { Email } from "sharedTypes"
import { BuilderBlockType, ButtonBlock } from "sharedTypes/builder"

type Props = {
  eventId: string
  email?: Email
}

const useFormIntegrations = ({ eventId, email }: Props) => {
  const { isLoading, data } = useForms(eventId)

  if (!email) {
    return { data: [], isLoading, deletedFormsCount: 0 }
  }

  const formIds = getFormIdsFromEmail(email)
  const integratedForms = data?.data.filter((form) => formIds.includes(form.externalId)) || []
  const deletedFormsCount = formIds.length - integratedForms.length

  return {
    data: integratedForms,
    isLoading,
    deletedFormsCount,
  }
}

const getFormIdsFromEmail = (email: Email) => {
  const { emailData: { blocks } } = email

  return (blocks.filter((block) => block.type === BuilderBlockType.BUTTON && block.data.action === "form") as ButtonBlock[])
    .map((block) => block.data.formId)
}

export default useFormIntegrations
