import React, { useState } from "react"
import {
  Flex, Stack, Text, Divider,
} from "@chakra-ui/react"
import * as Yup from "yup"
import Button from "components/Buttons/Button"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import Menu, { MenuItem } from "components/dialogs/Menu"
import { useParams } from "react-router-dom"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import { DioboxEvent, ModalName, TextMessage } from "sharedTypes"
import * as api from "api/texts"
import { successToast } from "utilities/toasts"
import useModal from "services/useModal"
import { useQueryClient } from "react-query"
import ConfirmationModal from "modals/ConfirmationModal"
import { refetchTextMessages, updateTextMessages } from "context/actions"
import {
  TEXT_MESSAGE_BODY_CHARACTER_LIMIT,
} from "pages/EventShow/Texts/SelectedTextMessage/SelectedTextMessage"
import FormContent from "../FormContent/FormContent"

type Props = {
  event: DioboxEvent
  selectedTextMessage: TextMessage
  onSave: (textMessage: TextMessage) => void
  onDelete: () => void
  showSavedLabel: boolean
}

const DraftTextMessage = ({
  event, selectedTextMessage, onSave, onDelete, showSavedLabel,
}: Props) => {
  const { eventId, textMessageId } = useParams<{ eventId: string, textMessageId: string }>()
  const showModal = useModal()
  const queryClient = useQueryClient()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const validationSchema = Yup.object().shape({
    body: Yup.string().max(TEXT_MESSAGE_BODY_CHARACTER_LIMIT),
    recipients: Yup.array().min(1),
  })

  const handleSubmit = async (textMessage?: TextMessage) => {
    if (textMessage) {
      await api.putTextMessage(
        eventId, textMessageId, textMessage,
      )
    }

    return api.sendTextMessage(eventId, textMessageId)
      .then(({ data }) => {
        updateTextMessages(queryClient, textMessageId, data)
        refetchTextMessages(queryClient, eventId)
        setShowConfirmationModal(false)
        successToast({ title: "Text Message sent to all recipients" })
      })
  }

  return (
    <Stack spacing={7}>
      <Flex justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="semibold">
          {selectedTextMessage.name}
        </Text>
        <Flex justifyContent="flex-end" alignItems="center" gap={2}>
          <Button onClick={() => showModal(ModalName.PreviewTextMessage, { textMessage: selectedTextMessage })} variant="outline" size="sm">Preview + Send Test</Button>
          <Menu
            noPadding
            buttonText="More"
            menuItems={[
              <MenuItem onClick={() => showModal(ModalName.EditTextMessageName,
                { onSubmit: onSave, initialValues: selectedTextMessage })}
              >Rename
              </MenuItem>,
              <MenuItem onClick={() => {}}>Duplicate Text</MenuItem>,
              <Divider />,
              <ConfirmDelete type="Text">
                <MenuItem onClick={onDelete} color="red.700">Delete</MenuItem>
              </ConfirmDelete>,
            ]}
          />
        </Flex>
      </Flex>
      <ContainedForm
        values={{ ...selectedTextMessage, type: "sms" }}
        onSubmit={() => setShowConfirmationModal(true)}
        submitButtonText="Send All"
        showCancel={false}
        disableSubmitWhenNotDirty={false}
        validationSchema={validationSchema}
      >
        {({
          control, watch, getValues, formState: { isDirty },
        }) => (
          <>
            <FormContent
              control={control}
              event={event}
              selectedTextMessage={selectedTextMessage}
              handleSave={onSave}
              watch={watch}
              showSavedLabel={showSavedLabel}
            />
            {showConfirmationModal && (
            <ConfirmationModal
              onClose={() => setShowConfirmationModal(false)}
              onSubmit={() => handleSubmit(isDirty ? getValues() : undefined)}
              text="You are about to send your invitation to x recipients"
            />
            )}
          </>
        )}
      </ContainedForm>
    </Stack>
  )
}

export default DraftTextMessage
