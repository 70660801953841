import {
  Flex, Heading, Text, Box,
} from "@chakra-ui/react"
import StatRow from "components/StatRow"
import React from "react"
import Link from "components/elements/Link"
import { asNumber } from "utilities/strings"

const TextMessageEngagements = () => (
  <Box>
    <Box borderBottomWidth={1} pb={4} mb={3}><Text fontSize="2xl">Text Deliverability</Text></Box>
    <Box ml={5}>
      <Flex justify="space-between" mb={6}>
        <Heading fontSize="lg" fontWeight="normal">All Recipients</Heading>
        <Text fontSize="lg">{asNumber(412)}</Text>
      </Flex>
      <Flex flexDirection="column" gap={0.5}>
        <StatRow label="Delivered" count={2} total={10} color="green.200" />
        <StatRow label="Message cannot be sent" count={3} total={10} color="green.400" />
        <StatRow label="Unreachable destination handset" count={4 - 2} total={10} color="gray.500" />
        <StatRow label="Content size exceeds carrier limit" count={3} total={10} color="pink.700" />
        <StatRow label="Blocked by the carrier" count={4} total={10} color="yellow.300" />
        <StatRow label="Unknown error" count={4} total={10} color="yellow.300" />
      </Flex>
      <Text mt={5}>
        <>To learn more, see: </>
        <Link target="_blank" href="#">SMS deliverability and error messages</Link>
      </Text>
    </Box>
  </Box>
)

export default TextMessageEngagements
