import React, { Dispatch } from "react"
import SidePanelForm from "components/Builder/components/SidePanelForm"
import { FixedBlockType, Form, Translations } from "sharedTypes"
import RegistrationSettings from "./RegistrationSettings"
import SubmissionSettings from "./SubmissionSettings"
import PlusOneSettings from "./PlusOneSettings"
import MessagingSettings from "./MessagingSettings"

type Props = {
  form: Form
  setForm: Dispatch<React.SetStateAction<Form | undefined>>
  selectedFixedBlockType: FixedBlockType
  onCancel: (form) => void
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const FixedBlockEditorPanel = ({
  form: { type, externalId },
  form,
  setForm,
  selectedFixedBlockType,
  onCancel,
  updateEvent,
  defaultTranslations,
}: Props) => {
  const saveForm = (values) => {
    setForm(values)
  }

  const initialValues = {
    profileNameRequired: true,
    profileEmailRequired: true,
    ...form,
  }

  return (
    <SidePanelForm
      onChange={saveForm}
      onCancel={onCancel}
      initialValues={initialValues}
    >
      {({ control }) => {
        switch (selectedFixedBlockType) {
          case FixedBlockType.Registration:
            return <RegistrationSettings form={form} control={control} />
          case FixedBlockType.GuestName:
            return (
              <MessagingSettings
                updateEvent={updateEvent}
                defaultTranslations={defaultTranslations}
                form={form}
                control={control}
              />
            )
          case FixedBlockType.Payment:
            return (
              <SubmissionSettings
                updateEvent={updateEvent}
                defaultTranslations={defaultTranslations}
                type={type}
                formId={externalId}
              />
            )
          case FixedBlockType.FormSubmission:
            return (
              <SubmissionSettings
                updateEvent={updateEvent}
                defaultTranslations={defaultTranslations}
                type={type}
                formId={externalId}
              />
            )
          case FixedBlockType.PlusOnes:
            return (
              <PlusOneSettings
                control={control}
                updateEvent={updateEvent}
                defaultTranslations={defaultTranslations}
                type={type}
                formId={externalId}
              />
            )
          default:
            return <></>
        }
      }}
    </SidePanelForm>
  )
}

export default FixedBlockEditorPanel
