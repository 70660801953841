import React from "react"
import Pill from "components/elements/Pill"
import { Flex } from "@chakra-ui/react"

type Props = {
  value: string;
  primary: boolean;
}

const EmailField = ({ value, primary }: Props) => (
  <Flex>
    {value}
    {primary && <PrimaryPill />}
  </Flex>
)

export const PrimaryPill = () => (
  <Pill
    ml={2}
    label="Primary"
    borderWidth={1}
    borderColor="green.500"
    bgColor="white"
    textColor="green.500"
  />
)

export default EmailField
