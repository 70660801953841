import useCurrentAccount from "services/useCurrentAccount"
import { useModalStore } from "services/useModal"
import { useAccountUsers } from "queries/accountUsers"
import {
  Redirect, Route, Switch, useHistory,
} from "react-router-dom"
import React, { useState } from "react"
import {
  User, Account as DioboxAccount, AccountUser,
} from "sharedTypes"
import {
  accountPath,
  accountPlanPath,
  accountTeamPath, completeRegistrationPath,
  confirmEmailPath, eventsFuturePath, eventsPastPath,
  eventsPath, upgradePath,
} from "utilities/routes"
import ConfirmEmail from "pages/Unauthenticated/ConfirmEmail"
import Account from "pages/Account"
import Plan from "pages/Account/Plan"
import TeamMembers from "pages/Account/TeamMembers"
import UpgradePlan from "modals/UpgradePlan"
import Pay from "modals/Pay"
import EventsIndex from "pages/EventsIndex"
import EventShow from "pages/EventShow"
import DownloadApp from "pages/DownloadApp"
import { PusherProvider } from "@harelpls/use-pusher"
import CompleteRegistration from "pages/Unauthenticated/CompleteRegistration"
import DesktopView from "../layout/DesktopView"
import PusherWrapper from "../PusherWrapper"
import ProtectedRoute from "../ProtectedRoute"
import Navigation from "../Navigation"
import ErrorView from "../ErrorView"
import Spinner from "../elements/Spinner"
import Modals from "./Modals"
import AccountErrors from "./AccountErrors"

type Props = {
  currentUser: User
  onClickForceDesktopView: () => void
  view: "mobile" | "desktop"
}

const Authenticated = ({ currentUser, onClickForceDesktopView, view }: Props) => {
  const currentAccount = useCurrentAccount()
  const currentModal = useModalStore((s) => s.modal)
  const { data: accountUsers, isLoading: accountUsersIsloading } = useAccountUsers(
    currentAccount ? currentAccount.disabled : true,
  )
  const [
    closedPastDueNotificationAccountIDs,
    setClosedPastDueNotificationAccountIDs,
  ] = useState<string[]>([])

  if (accountUsersIsloading) {
    return <Spinner />
  }

  if (!accountUsers) {
    return null
  }

  return (
    // @ts-ignore
    <PusherProvider
      clientKey={import.meta.env.VITE_PUSHER_KEY!}
      cluster={import.meta.env.VITE_PUSHER_CLUSTER!}
    >
      <AccountErrors
        currentAccount={currentAccount}
        currentUser={currentUser}
        setClosedPastDueNotificationAccountIDs={setClosedPastDueNotificationAccountIDs}
        closedPastDueNotificationAccountIDs={closedPastDueNotificationAccountIDs}
      />
      {currentModal?.name && (
        <Modals
          currentAccount={currentAccount}
          currentUser={currentUser}
          currentModal={currentModal}
          teamMembers={accountUsers}
        />
      )}
      {view === "desktop" ? (
        <DesktopView>
          <DesktopRoutes
            currentAccount={currentAccount}
            accountUsers={accountUsers}
            setClosedPastDueNotificationAccountIDs={setClosedPastDueNotificationAccountIDs}
            currentUser={currentUser}
          />
        </DesktopView>
      ) : (
        <Switch>
          {completeRegistrationRoute()}
          <Route>
            <DownloadApp onClickForceDesktopView={onClickForceDesktopView} />
          </Route>
        </Switch>
      )}
    </PusherProvider>
  )
}

const completeRegistrationRoute = () => (
  <Route path={completeRegistrationPath()} exact>
    <CompleteRegistration />
  </Route>
)

type AuthenticatedRoutesProps = {
  currentAccount: DioboxAccount,
  currentUser: User,
  setClosedPastDueNotificationAccountIDs: (ids: (prevIDs) => any) => void
  accountUsers: AccountUser[]
}

const DesktopRoutes = ({
  currentAccount, currentUser, accountUsers, setClosedPastDueNotificationAccountIDs,
}: AuthenticatedRoutesProps) => {
  const history = useHistory()
  const { admin } = currentAccount

  const handleClickResolve = () => {
    setClosedPastDueNotificationAccountIDs(
      (prevIDs) => prevIDs.filter((id) => id !== currentAccount.id),
    )
  }

  return (
    <Switch>
      {completeRegistrationRoute()}
      <Route path="/error" exact>
        <ErrorView clearError={() => {
        }}
        />
      </Route>
      <Route>
        <Navigation currentUser={currentUser}>
          <Switch>
            <Route path={confirmEmailPath()} exact>
              <ConfirmEmail currentUser={currentUser} />
            </Route>
            <ProtectedRoute
              exact
              path={accountPath()}
              authorized={admin}
              redirectTo={eventsPath()}
            >
              <Account currentUser={currentUser} account={currentAccount} />
            </ProtectedRoute>
            <ProtectedRoute
              path={accountPlanPath()}
              authorized={admin}
              redirectTo={eventsPath()}
            >
              <Plan
                subscription={currentAccount.subscription}
                showBilling={!!currentAccount.cardBrand}
                onClickResolve={handleClickResolve}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path={accountTeamPath()}
              authorized={admin}
              redirectTo={eventsPath()}
            >
              <TeamMembers
                currentUser={currentUser}
                account={currentAccount}
                accountUsers={accountUsers}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path={upgradePath()}
              authorized={admin}
              redirectTo={eventsPath()}
            >
              <UpgradePlan
                currentAccount={currentAccount}
                onHide={() => history.push(accountPlanPath())}
              />
            </ProtectedRoute>
            <ProtectedRoute
              path="/pay/:chargeConfigurationId"
              authorized={admin}
              redirectTo={eventsPath()}
            >
              <Pay
                currentAccount={currentAccount}
                onHide={() => history.push(accountPlanPath())}
              />
            </ProtectedRoute>
            <Route path={[eventsPath(), eventsFuturePath(), eventsPastPath()]} exact>
              <EventsIndex onClickResolve={handleClickResolve} currentUser={currentUser} />
            </Route>
            <Route path="/events/:eventId">
              <PusherWrapper>
                <EventShow
                  currentUser={currentUser}
                  accountUsers={accountUsers}
                />
              </PusherWrapper>
            </Route>
            <Route>
              <Redirect to={eventsPath()} />
            </Route>
          </Switch>
        </Navigation>
      </Route>
    </Switch>
  )
}

export default Authenticated
