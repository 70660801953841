import React from "react"
import EditorSection from "components/Builder/components/EditorSection"
import FontSelect from "components/Builder/components/FontSelect"
import PaddingInputs from "components/Builder/components/PaddingInputs"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import ColorSelect from "components/ReactHookForm/ColorSelect"
import NumberField from "components/ReactHookForm/NumberField"
import RichTextField, { RichTextFieldType } from "components/ReactHookForm/RichTextField"
import { Control } from "react-hook-form"

type Props = {
  control: Control
  type?: RichTextFieldType
}

const TextFields = ({ control, type = RichTextFieldType.EMAIL_BLOCK_TEXT }: Props) => (
  <>
    <SidePanelHeader>Text Block</SidePanelHeader>
    <EditorSection>
      <RichTextField name="text" control={control} type={type} />
    </EditorSection>
    <EditorSection>
      <FontSelect control={control} />
      <NumberField label="Text Size" name="size" control={control} min={1} />
      <NumberField label="Line Height" name="lineHeight" control={control} min={1} step={0.1} />
    </EditorSection>
    <EditorSection>
      <PaddingInputs control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Text Color" name="textColor" control={control} />
    </EditorSection>
    <EditorSection>
      <ColorSelect label="Block Color" name="blockColor" control={control} colorScheme="light" />
    </EditorSection>
  </>
)

export default TextFields
