import React, { useEffect, useState } from "react"
import {
  Account, ModalName, AccountUser, User,
} from "sharedTypes"
import BackLink from "components/Navigation/BackLink"
import { accountTeamPath, eventPath } from "utilities/routes"
import {
  Box, Heading, Table, Tbody, Text, Thead,
} from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import Checkbox from "components/ReactHookForm/Checkbox"
import { useMutation, useQueryClient } from "react-query"
import { putAccountUser } from "api/accounts"
import { updateAccountUser } from "context/actions"
import _ from "lodash"
import { useEvents } from "queries/events"
import Spinner from "components/elements/Spinner"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import EventWithAvatar from "components/elements/EventWithAvatar"
import useModal from "services/useModal"
import { asDate } from "utilities/strings"
import { successToast } from "utilities/toasts"
import { useWatch } from "react-hook-form"
import TeamMemberWithAvatar from "./components/TeamMemberWithAvatar"
import {
  Td, Th, TheadTr, Tr,
} from "./components/Table"

type Props = {
  currentUser: User;
  account: Account;
  accountUser: AccountUser;
}

const TeamMemberShow = ({ currentUser, account, accountUser }: Props) => {
  const { admin, canCreateEvents, events: teamMemberEvents } = accountUser || {}
  const queryClient = useQueryClient()
  const { isLoading, data: events } = useEvents(account.id)
  const [eventOrder, setEventOrder] = useState<"asc"|"desc">("asc")
  const [showEventAccess, setShowEventAccessTo] = useState(!admin)
  const showModal = useModal()

  const { mutateAsync } = useMutation((
    data: {admin: boolean, canCreateEvents: boolean},
  ) => putAccountUser({ ...accountUser, ...data }), {
    onSuccess: ({ data }) => {
      updateAccountUser(queryClient, data)
      successToast({ title: "Team Member Saved" })
    },
  })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Box mx={24} my={10}>
      <BackLink to={accountTeamPath()}>Return to Team</BackLink>
      <Box mt={12}>
        <TeamMemberWithAvatar
          teamMember={accountUser}
          isCurrent={accountUser?.user?.id === currentUser.id}
        />
      </Box>
      <Heading mt={10} mb={6} fontSize="2xl" fontWeight="semibold">Account Access</Heading>
      <ContainedForm values={{ admin, canCreateEvents }} onSubmit={mutateAsync}>
        {({
          control, setValue, reset,
        }) => (
          <TeamMemberPermissionsForm
            control={control}
            onChangeFieldValue={setValue}
            onReset={reset}
            onChangeEventAccess={setShowEventAccessTo}
          />
        )}
      </ContainedForm>
      {showEventAccess && (
        <>
          <Heading mt={14} mb={6} fontSize="2xl" fontWeight="semibold">Event Access</Heading>
          <Table borderTopRadius="md" borderWidth="2" overflow="hidden">
            <Thead>
              <TheadTr>
                <Th colspan={4} py={2}>
                  <Button
                    size="sm"
                    rightIcon={`${eventOrder === "asc" ? "up" : "down"}-arrow`}
                    onClick={() => setEventOrder(eventOrder === "asc" ? "desc" : "asc")}
                    variant="link"
                  >
                    Sort
                  </Button>
                </Th>
              </TheadTr>
            </Thead>
            <Tbody>
              {_.orderBy(events, "title", eventOrder).map((event) => {
                const access = teamMemberEvents.find(
                  ({ event: { id: eventId } }) => eventId === event.id,
                )

                return (
                  <Tr key={event.id} role="button" onClick={() => window.open(eventPath(event.id))}>
                    <Td>
                      <EventWithAvatar event={event} />
                    </Td>
                    <Td>{asDate(event.startDate, "MMMM d, yyyy")}</Td>
                    <Td fontWeight="semibold">{access ? _.startCase(access.role) : "No Access"}</Td>
                    <Td textAlign="right">
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={(e) => {
                          e.stopPropagation()
                          showModal(ModalName.AccountUserForm, { accountUser, currentEvent: event })
                        }}
                      >
                        Edit
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </>
      )}
    </Box>
  )
}

const TeamMemberPermissionsForm = ({
  onReset, onChangeFieldValue, onChangeEventAccess, control,
}) => {
  const [admin, canCreateEvents] = useWatch({ name: ["admin", "canCreateEvents"], control })
  useEffect(() => {
    onChangeEventAccess(!admin)

    if (admin) {
      onChangeFieldValue("canCreateEvents", true)
    } else {
      onReset({ admin, canCreateEvents }, { keepDefaultValues: true })
    }
  }, [admin])

  return (
    <Box pt={4}>
      <Checkbox
        control={control}
        name="admin"
        label={(
          <>
            <Text mb={1} fontSize="lg" fontWeight="semibold">Account Admin</Text>
            <Text fontSize="sm">Provides access to all events, account settings, and team
              management
            </Text>
          </>
        )}
      />
      <Checkbox
        control={control}
        name="canCreateEvents"
        label={(
          <>
            <Text mb={1} fontSize="lg" fontWeight="semibold">Create New Events</Text>
            <Text fontSize="sm">Allows team member to create new events on their own</Text>
          </>
        )}
        disabled={admin}
      />
    </Box>
  )
}

export default TeamMemberShow
