import React from "react"
import {
  Box,
  Divider, Flex, Stack, Text,
} from "@chakra-ui/react"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import Menu, { MenuItem } from "components/dialogs/Menu"
import useModal from "services/useModal"
import { DioboxEvent, ModalName, TextMessage } from "sharedTypes"
import Card from "components/layout/Card"
import { asDate } from "utilities/strings"
import TextBubble from "modals/PreviewTextMessage/Phone/TextBubble"
import FormIntegrations from "pages/EventShow/Emails/SentEmail/FormIntegrations/FormIntegrations"
import useMergeTags from "services/useMergeTags"
import TextMessageEngagements from "./TextMessageEngagements"

type Props = {
  selectedTextMessage: TextMessage
  onSave: (textMessage: TextMessage) => void
  onDelete: () => void
  event: DioboxEvent
}

const SentTextMessage = ({
  selectedTextMessage: {
    name,
    sentAt,
    body,
  },
  selectedTextMessage,
  onSave,
  onDelete,
  event: { id: eventId },
  event,
}: Props) => {
  const showModal = useModal()
  const { getFormsFromTextBody } = useMergeTags({ eventId })
  const forms = getFormsFromTextBody(body)

  if (!sentAt) {
    return null
  }

  return (
    <Stack spacing={7}>
      <Flex justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="semibold">
          {name}
        </Text>
        <Menu
          noPadding
          buttonText="Text Options"
          menuItems={[
            <MenuItem onClick={() => showModal(ModalName.EditTextMessageName,
              { onSubmit: onSave, initialValues: selectedTextMessage })}
            >Rename
            </MenuItem>,
            <MenuItem onClick={() => {}}>Duplicate Text</MenuItem>,
            <Divider />,
            <MenuItem onClick={() => {}}>View Text Report</MenuItem>,
            <MenuItem onClick={() => {}}>Export Text Report</MenuItem>,
            <Divider />,
            <ConfirmDelete type="Text">
              <MenuItem onClick={onDelete} color="red.700">Delete</MenuItem>
            </ConfirmDelete>,
          ]}
        />
      </Flex>
      <Card>
        <Flex flexDirection="column" alignItems="center">
          <Text fontWeight="bold">From: +1 833-537-1178</Text>
          <Text fontWeight="bold" mb={2}>Sent: {asDate(sentAt)}</Text>
          <TextBubble text={body} />
          <Stack w="full" mt={12} spacing={12}>
            <TextMessageEngagements />
            {forms.length > 0 && (
              <Box>
                <Box borderBottomWidth={1} pb={4} mb={3}><Text fontSize="2xl">Form Integration</Text></Box>
                <Box ml={5}>
                  <FormIntegrations
                    event={event}
                    forms={forms}
                    deletedFormsCount={0}
                  />
                </Box>
              </Box>
            )}
          </Stack>
        </Flex>
      </Card>
    </Stack>
  )
}

export default SentTextMessage
