import React, { FormEvent, useEffect, useState } from "react"
import {
  Flex,
  FormControl,
  Text,
  Box,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from "@chakra-ui/react"
import { ChromePicker } from "react-color"
import { COLOR_SCHEMES } from "utilities/enums"
import { Control, useController } from "react-hook-form"

type Props = {
  name: string;
  control: Control<any>;
  label?: string;
  error?: boolean;
  colorScheme?: "dark" | "light";
}

const CompactColorSelect = ({
  name, control, label, error, colorScheme = "dark",
}: Props) => {
  const { field } = useController({ name, control })
  const [color, setColor] = useState(field.value)

  useEffect(() => {
    setColor(field.value)
  }, [field.value])

  const handleInput = (e: FormEvent<HTMLInputElement|HTMLButtonElement>) => {
    const hex = (e.target as HTMLInputElement).value
    setColor(hex)
    if (!/^#[0-9A-F]{6}$/i.test(hex)) {
      return
    }

    field.onChange(hex)
  }

  return (
    <FormControl isInvalid={error}>
      <Box>
        {label && <Text fontWeight="bold">{label}</Text>}
        <Flex direction="column" align="start" w="100%" mt={4}>
          <Popover variant="responsive">
            <PopoverTrigger>
              <InputGroup>
                <InputLeftAddon>
                  <Text>Hex</Text>
                </InputLeftAddon>
                <Input
                  variant="outline"
                  bg="white"
                  onChange={handleInput}
                  value={color}
                />
                <InputRightElement>
                  <Box
                    backgroundColor={color}
                    borderWidth={1}
                    w={6}
                    h={6}
                    borderRadius="md"
                  />
                </InputRightElement>
              </InputGroup>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody>
                <ChromePicker
                  color={color}
                  onChange={(c) => setColor(c.hex)}
                  onChangeComplete={(c) => field.onChange(c.hex)}
                  presetColors={COLOR_SCHEMES[colorScheme!]}
                  disableAlpha
                  width={200}
                />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
      </Box>
    </FormControl>
  )
}

export default CompactColorSelect
