import React, { useState } from "react"
import * as api from "api/guests"
import { addGuestTransaction } from "context/actions"
import { Flex, Box, Divider } from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import { useQueryClient } from "react-query"
import _ from "lodash"
import { Guest } from "sharedTypes"
import CheckInButton from "./CheckInButton/CheckInButton"
import GroupCheckInButton from "./GroupCheckInButton/GroupCheckInButton"
import SelectActivityButton from "./SelectActivityButton/SelectActivityButton"

export const inverseStyles = {
  bg: "white",
  color: "green.500",
  borderWidth: 2,
  borderColor: "green.500",
}

export const defaultStyles = {
  bg: "green.500",
  color: "white",
}

interface Props {
  guest: Guest
}

const CheckInItem = ({ guest }: Props) => {
  const queryClient = useQueryClient()
  const {
    id: guestId,
    personInfo: {
      transactions,
    },
    group,
  } = guest
  const { data: { timezone, checkInLocations, id: eventId } } = useCurrentEvent()
  const [checkInLocationId, setCheckInLocationId] = useState(null)

  const handleClickLocation = (id) => setCheckInLocationId(
    (prevCheckInLocationId) => {
      if (prevCheckInLocationId !== id) { return id }

      return prevCheckInLocationId
    },
  )
  const checkInLocationsWithCheckInOption = [{ id: null, name: "Check-In" }, ..._.sortBy(checkInLocations, "name")]

  const checkIn = (gId, gender = null) => {
    api.postCheckIn(eventId, gId, checkInLocationId || null, gender)
      .then(({ data }) => {
        addGuestTransaction(queryClient, data)
      })
  }

  return (
    <Box py={6} position="relative" zIndex="0">
      <Divider w={96} m="auto" pos="relative" top={10} borderWidth={1.5} borderColor="green.500" opacity={1} />
      <Flex align="center" justify="space-between" w="full" px={20}>
        <SelectActivityButton
          checkInLocationsWithCheckInOption={checkInLocationsWithCheckInOption}
          checkInLocationId={checkInLocationId}
          checkInLocations={checkInLocations}
          handleClickLocation={handleClickLocation}
          eventId={eventId}
        />
        <CheckInButton
          transactions={transactions}
          checkIn={checkIn}
          guestId={guestId}
          timezone={timezone}
          checkInLocationsWithCheckInOption={checkInLocationsWithCheckInOption}
          checkInLocationId={checkInLocationId}
          eventId={eventId}
        />
        <GroupCheckInButton
          checkIn={checkIn}
          checkInLocationId={checkInLocationId}
          eventId={eventId}
          group={group}
          guestId={guestId}
        />
      </Flex>
    </Box>
  )
}

export default CheckInItem
