import React, { useState } from "react"
import * as api from "api/guests"
import { ImportData, ImportGuestsHeader } from "sharedTypes"
import Upload from "modals/ImportGuests/Upload"
import ManageFields from "modals/ImportGuests/ManageFields"
import ImportOptions from "modals/ImportGuests/ImportOptions"
import { infoToast } from "utilities/toasts"
import { remainingGuestSpots } from "pages/EventShow/Guests"
import Upgrade from "./Upgrade"

enum Screens {
  UploadScreen,
  ManageFieldsScreen,
  ImportSettingsScreen,
  UpgradeAlert,
}

const initialImportData = {
  examples: [[]],
  headers: true,
  importFileId: null,
  rowCount: 0,
}

type Props = {
  eventId: string
  guestsCount: number
  ticketedGuestsCount: number
  importLimit: number|null
  onClose: () => void
}

const ImportGuests = ({
  eventId, guestsCount, ticketedGuestsCount, importLimit, onClose,
}: Props) => {
  const [screen, setScreen] = useState(Screens.UploadScreen)
  const [importData, setImportData] = useState<ImportData>(initialImportData)
  const [
    headerConfiguration,
    setHeaderConfiguration,
  ] = useState<(ImportGuestsHeader | undefined)[]>([])
  const [importOptions, setImportOptions] = useState({ updateExistingPeople: false, guestList: "all" })

  const numberOfRecords = importData.rowCount
  const remainingSpots = remainingGuestSpots(guestsCount, ticketedGuestsCount, importLimit)

  const headerConfigurationChangeHandler = (configuration) => {
    setHeaderConfiguration(configuration)
  }

  const uploadHandler = async (importFile, containsHeaders) => {
    setHeaderConfiguration([])

    const returnValue = {
      success: true,
      errorMessage: "",
    }

    await api.postImportFile(eventId, importFile, containsHeaders)
      .then((response) => {
        const { data, data: { rowCount } } = response
        if (rowCount) {
          setImportData(data)

          if (rowCount > remainingSpots) {
            setScreen(Screens.UpgradeAlert)
          } else {
            setScreen(Screens.ManageFieldsScreen)
          }
        }
      })
      .catch(() => {
        // The error message is handled by the global error handler
        returnValue.success = false
      })

    return returnValue
  }

  const saveHandler = (updateExistingPeople, guestList) => {
    setImportOptions({ updateExistingPeople, guestList })

    if (!importData.importFileId) {
      return
    }

    const headers = headerConfiguration.map((header) => {
      if (!header) {
        return { used: false }
      }

      const {
        key, value, custom, type,
      } = header

      return {
        key: custom ? "custom_fields" : key,
        value,
        used: true,
        type,
      }
    })

    api.postImportGuests(
      eventId,
      importData.importFileId,
      updateExistingPeople,
      headers,
      guestList,
    ).then(() => {
      infoToast({ title: "Import started. The time to process depends on the number of guests in your file." })
      setImportData(initialImportData)
      setScreen(Screens.UploadScreen)
      onClose()
    })
  }

  switch (screen) {
    case Screens.UploadScreen:
    default:
      return <Upload onUpload={uploadHandler} onClose={onClose} />
    case Screens.ManageFieldsScreen:
      if (!importData.examples) {
        return null
      }

      return (
        <ManageFields
          headerConfiguration={headerConfiguration}
          onChangeHeaderConfiguration={headerConfigurationChangeHandler}
          examples={importData.examples}
          containsHeader={importData.headers}
          onClickBack={() => setScreen(Screens.UploadScreen)}
          onClickNext={() => setScreen(Screens.ImportSettingsScreen)}
          onClose={onClose}
        />
      )
    case Screens.ImportSettingsScreen:
      return (
        <ImportOptions
          values={importOptions}
          numberOfRecords={numberOfRecords}
          onSaveImport={saveHandler}
          onClickBack={(updateExistingPeople, guestList) => {
            setImportOptions({ updateExistingPeople, guestList })
            setScreen(Screens.ManageFieldsScreen)
          }}
          onClose={onClose}
        />
      )
    case Screens.UpgradeAlert:
      return (
        <Upgrade
          numberOfRecords={numberOfRecords}
          maxNumberOfRecords={importLimit}
          remainingSpots={remainingSpots}
          onClickBack={() => setScreen(Screens.UploadScreen)}
          onClose={onClose}
        />
      )
  }
}

export default ImportGuests
