import React, { useRef, useState } from "react"
import {
  Box, FormControl, FormHelperText, FormLabel, Image,
} from "@chakra-ui/react"
import { Control, FieldValues, useController } from "react-hook-form"
import ImageUploadComponent from "components/ImageUploadComponent"

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T, object>;
  alt?: string;
  label?: string;
  vertical?: boolean;
  imageUrl?: string;
  imageWidth?: string|number;
  imageHeight?: string|number;
  imageFit?: "contain" | "cover";
  hideImageBorder?: boolean;
  Component?: React.FC<any>;
  helperText?: string;
  disabled?: boolean;
}

const ImageUpload = <T extends FieldValues, >({
  name,
  control,
  alt,
  label,
  vertical,
  imageUrl,
  imageWidth,
  imageHeight,
  imageFit = "cover",
  hideImageBorder,
  helperText,
  disabled,
  Component = ImageUploadComponent,
}: Props<T>) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [removed, setRemoved] = useState(false)
  const [touched, setTouched] = useState(false)
  const { field } = useController<any>({ name, control })
  const { field: urlField } = useController<any>({ name: `${name}Url`, control })

  const fileInputProps = {
    ref: fileInputRef,
    id: name,
    type: "file",
    accept: "image/*",
    onChange: (e) => {
      setRemoved(false)
      field.onChange(e.target.files[0])
      setTouched(true)
      e.target.value = null
    },
  }

  let imgSrc = imageUrl
  if (touched && field.value) {
    try {
      imgSrc = URL.createObjectURL(field.value)
    } catch (e) {
      imgSrc = imageUrl
    }
  }

  let imagePreview
  if (imgSrc) {
    imagePreview = (
      <Image
        ignoreFallback
        src={imgSrc}
        alt={alt}
        w={imageWidth}
        h={imageHeight}
        fit={imageFit}
        borderWidth={hideImageBorder ? 0 : 1}
        borderRadius="md"
        borderColor="gray.400"
        borderStyle="solid"
      />
    )
  }

  return (
    <FormControl mb={4} display={vertical ? "initial" : "flex"} alignItems="center">
      {label && <FormLabel fontWeight="bold" w={vertical ? "auto" : 1 / 4}>{label}</FormLabel>}
      <Box w="100%">
        <Component
          imagePreview={imagePreview}
          fileInputRef={fileInputRef}
          removed={removed}
          onRemove={() => {
            field.onChange(null)
            urlField.onChange(null)
            setRemoved(true)
          }}
          disabled={disabled}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        <input {...fileInputProps} style={{ display: "none" }} data-testid="image-upload" />
      </Box>
    </FormControl>
  )
}

ImageUpload.defaultProps = {
  imageWidth: "auto",
  imageHeight: "auto",
  hideImageBorder: false,
}

export default ImageUpload
