import {
  del, get, patch, post, put,
} from "services/api"
import { currentAccountId } from "services/authentication"
import axios from "axios"
import {
  CheckInTransaction,
  Guest, GuestStatus, GuestSummary, ImportData, PersonInfo, Segment, Table, Transaction,
} from "sharedTypes"
import { DeepPartial } from "utilities/types"

const { CancelToken } = axios
let cancelGuestSearch

export const getGuests = (
  eventId: string,
  page: number,
  search?: string,
  groupId?: string | number,
  orderBy?: string,
  segmentId?: string | number,
) => {
  if (cancelGuestSearch && page === 1) {
    cancelGuestSearch("Operation canceled by the user.")
  }

  let searchQuery = ""
  if (search) {
    searchQuery = `&search=${search}`
  }

  let filterQuery = ""
  if (groupId) {
    filterQuery = `&group_id=${groupId}`
  }
  if (segmentId) {
    filterQuery = `&segment_id=${segmentId}`
  }

  const orderQuery = `&order=${orderBy || "first_name_asc"}`

  return get<GuestSummary[]>(
    `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}`
      + `/events/${eventId}`
      + `/guests.json?page=${page}${searchQuery}${orderQuery}${filterQuery}&per_page=100`,
    {},
    {
      cancelToken: new CancelToken((c) => {
        // An executor const receives a cancel function as a parameter
        cancelGuestSearch = c
      }),
    },
  )
}

export const getGuest = (eventId: string, id: string) => get<Guest>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/guests/${id}`, {})

export const postGuest = (eventId: string, guest: {personId: string, guestId: string}) => post<Guest>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/guests.json`, guest)

export const putGuest = (eventId: string, id: string, guest: DeepPartial<Guest>) => put<Guest>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/guests/${id}`, guest)

export const deleteGuest = (
  eventId: string,
  id: string,
) => del<void>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/guests/${id}`)

export const postPerson = (person: { person: Partial<PersonInfo> }) => post<PersonInfo>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/people.json`, person)

export const postImportFile = (
  eventId: string, file: File, headers: { used: boolean; value?: string; key?: string }[],
) => post<ImportData>(
  `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/import_files/parse_file.json`,
  {
    pureJsonString: JSON.stringify({ headers }),
    file,
  },
)

export const postImportGuests = (
  eventId: string,
  importFileId: number,
  updateExistingPeople: boolean,
  headers: { used: boolean; value?: string; key?: string }[],
  guestList: string,
) => post<void>(
  `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/guests/import.json`,
  {
    account_id: currentAccountId(),
    event_id: eventId,
    action: "import",
    headers,
    import_file_id: importFileId,
    group_id: guestList,
    update_existing_people: updateExistingPeople.toString(),
  },
)

export const postExportGuests = (eventId: string, groupId?: string, segmentId?: string) => post<void>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/export.json`, { action: "export", groupId, segmentId })

export const putGuestProfilePicture = (
  eventId: string,
  personId: string,
  photo: File,
) => put<{photoUrl: string}>(
  `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/people/${personId}`,
  { photo },
)

export const postTransaction = (
  eventId: string,
  guestId: string,
  transaction: Partial<Transaction|CheckInTransaction>,
) => post<Transaction>(
  `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/guests/${guestId}/transactions.json`,
  transaction,
)

export const putTransaction = (
  eventId: string,
  guestId: string,
  transactionId: number,
  payload: object,
) => put<Transaction>(
  `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/guests/${guestId}/transactions/${transactionId}.json`,
  payload,
)

export const deleteTransaction = (
  eventId: string,
  guestId: string,
  transactionId: number,
) => del<Transaction>(`${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/guests/${guestId}/transactions/${transactionId}.json`, {})

export const postCheckIn = (
  eventId: string,
  guestId: string,
  checkInLocationId: number | null,
  gender: string | null,
) => postTransaction(
  eventId,
  guestId,
  {
    checkInLocationId,
    data: {
      gender,
    },
    type: "AttendanceTransaction",
  },
)

export const postCheckIns = (
  eventId: string,
  guestIds: string[],
  checkInLocationId: number | null,
) => post<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/check_in.json`,
  { ids: guestIds, checkInLocationId },
)

export const patchGuestsStatuses = (
  eventId: string,
  status: GuestStatus,
  ids?: string[],
  segmentId?: string,
  groupId?: string,
  sendConfirmationEmail?: boolean,
) => patch<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/update_status.json`,
  {
    status, ids, segmentId, groupId, sendConfirmationEmail: sendConfirmationEmail || undefined,
  },
)

export const postGuestsToGuestLists = (
  eventId: string,
  guestListIds: string[],
  guestIds: string[],
  segmentId?: string,
  groupId?: string,
) => post<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/add_to_guest_lists.json`,
  {
    ids: guestIds,
    guestListIds,
    segmentId,
    groupId,
  },
)

export const deleteGuestsFromGuestLists = (
  eventId: string,
  guestListIds: string[],
  guestIds: string[],
  segmentId?: string,
  groupId?: string,
) => del<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/remove_from_guest_lists.json`,
  {
    ids: guestIds,
    guestListIds,
    segmentId,
    groupId,
  },
)

export const postGuestsEmails = (
  eventId: string, emailId: number, ids?: string[], segmentId?: string, groupId?: string,
) => {
  let sendExtra
  if (ids) {
    sendExtra = Object.fromEntries(ids.map((guestId) => [`guest_${guestId}`, true]))
  } else if (segmentId) {
    sendExtra = { [`segment_${segmentId}`]: true }
  } else if (groupId) {
    const key = /^\d+$/.test(groupId) ? `group${groupId}` : `group_${groupId}`
    sendExtra = { [key]: true }
  }

  return post<{ guestCount: number }>(
    `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/events/${eventId}/invitations/${emailId}/additional_emails.json`,
    {
      id: emailId,
      sendExtra,
    },
  )
}

export const postGuestsSmartReminders = (
  eventId: string, subject: string, ids?: string[], segmentId?: string, groupId?: string,
) => post<{guestCount: number}>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/send_reminder.json`,
  {
    subject,
    ids,
    segmentId,
    groupId,
  },
)

export const deleteGuests = (
  eventId: string, ids?: string[], segmentId?: string, groupId?: string,
) => del<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/delete_multiple.json`,
  { ids, segmentId, groupId },
)

export const putGuestEnrich = (eventId: string, personId: string) => put<PersonInfo>(
  `${import.meta.env.VITE_API_BASE}/api/v2/${currentAccountId()}/people/${personId}/enhance.json`,
  {
    id: personId,
  },
)

export const postGuestSeat = (
  eventId: string, guest: GuestSummary, table: Table, number: number,
) => post<Guest>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/${guest.id}/seat.json`,
  {
    tableId: table.id,
    number,
  },
)

export const deleteGuestSeat = (
  eventId: string, guestId: string,
) => del<Guest>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/${guestId}/seat.json`)

export const postGuestTableWaitlist = (
  eventId: string, guestId: string, table: Table,
) => post<Guest>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/${guestId}/table_waitlist.json`,
  {
    tableId: table.id,
  },
)

export const postSegment = (
  eventId: string, segment: Segment,
) => post<Segment>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/segments.json`,
  { segment },
)

export const putSegment = (
  eventId: string, segment: Segment,
) => put<Segment>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/segments/${segment.id}.json`,
  { segment },
)

export const deleteSegment = (eventId: string, segmentId: string) => del<void>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/segments/${segmentId}.json`)

export const postPlusOnes = (
  eventId: string,
  guestId: string,
  params: { plusOnes: Partial<GuestSummary>[] } | { plusOnesCount: number },
) => post<Guest>(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/${guestId}/add_plus_ones.json`, params)

export const linkGuest = (eventId: string, parentId: string, childId: string) => patch<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/${childId}/link.json`,
  { guestId: parentId },
)

export const unlinkGuest = (eventId: string, childId: string) => patch<void>(
  `${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/guests/${childId}/unlink.json`,
)

export const postExportCheckIns = (eventId: string) => post(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/export_check_ins.json`)

export const postExportFormSubmissions = (eventId: string) => post(`${import.meta.env.VITE_API_BASE}/api/v3/accounts/${currentAccountId()}/events/${eventId}/export_form_submissions.json`)
