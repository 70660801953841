import React from "react"
import { Box, Flex, Text } from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { isFuture } from "date-fns"

type Props = {
  url: string
  startDate: string
  size?: number
  mb?: number
}

const CoverImage = ({
  url, startDate, size, mb,
}: Props) => {
  const futureEvent = isFuture(new Date(startDate))

  return (
    <Flex
      bgImage={url ? `url("${url}?fit=crop&w=320&h=320")` : ""}
      bgSize="cover"
      bgColor={futureEvent ? "gray.100" : ""}
      w={size}
      h={size}
      borderWidth={2}
      _hover={{ borderColor: "blue.400" }}
      mb={mb}
      position="relative"
      align="center"
      justify="center"
      shrink={0}
    >
      {!url && (
      <Icon
        icon="logo-v2"
        size={size! < 48 ? 8 : 12}
        color="gray.300"
        position="absolute"
        margin="auto"
        top={0}
        right={0}
        bottom={0}
        left={0}
      />
      )}
      {!futureEvent
      && (
        <>
          <Box w="full" h="full" bgColor="blackAlpha.500" />
          <Text
            color="white"
            position="absolute"
            bottom={size! < 48 ? "1%" : "10%"}
            fontWeight="semibold"
            fontSize={size! < 48 ? "xs" : "sm"}
          >Past Event
          </Text>
        </>
      )}
    </Flex>
  )
}

CoverImage.defaultProps = {
  size: 48,
}

export default CoverImage
