import React from "react"
import { Box } from "@chakra-ui/react"
import { DioboxEvent, Translations } from "sharedTypes"
import { useForm } from "react-hook-form"
import TicketFormsModal from "modals/EditMessaging/TicketFormsModal"
import RegistrationFormsModal from "modals/EditMessaging/RegistrationFormsModal"
import RsvpFormsModal from "modals/EditMessaging/RsvpFormsModal"
import WaitlistFormsModal from "modals/EditMessaging/WaitlistFormsModal"
import TranslationField from "../TranslationField"
import BoxWithEditModal from "./BoxWithEditModal"
import TitleWithSubtitle from "../../TitleWithSubtitle"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  defaultTranslations: Translations
}

const FormSpecificMessagingSection = ({ event, updateEvent, defaultTranslations }: Props) => {
  const { control } = useForm({
    values: event,
  })

  const onSubmit = (values) => updateEvent(values)

  return (
    <Box>
      <TitleWithSubtitle
        title="Form-Specific Messaging"
        subtitle="Each Diobox Form type includes its own set of customization options. You can edit these options either when
        building the form or by accessing them below."
        mb={5}
      />
      <Box borderRadius="lg" overflow="hidden" borderWidth={1}>
        <BoxWithEditModal
          title="Registration Forms"
          event={event}
          Modal={RegistrationFormsModal}
          onSubmit={onSubmit}
          defaultTranslations={defaultTranslations}
        >
          <TranslationField
            label="Submit Button Label"
            defaultTranslation={defaultTranslations.registrationSubmitButton}
            fieldName="translations.registrationSubmitButton"
            control={control}
          />
          <TranslationField
            label="Success Message"
            defaultTranslation={defaultTranslations.registrationSuccessMessage}
            fieldName="translations.registrationSuccessMessage"
            control={control}
          />
          <TranslationField
            label="Form Edited Message"
            defaultTranslation={defaultTranslations.registrationEditMessage}
            fieldName="translations.registrationEditMessage"
            control={control}
          />
          <TranslationField
            label="Closing Message"
            defaultTranslation={defaultTranslations.registrationClosingMessage}
            fieldName="translations.registrationClosingMessage"
            control={control}
          />
        </BoxWithEditModal>
        <BoxWithEditModal
          title="Ticket Forms"
          event={event}
          Modal={TicketFormsModal}
          onSubmit={onSubmit}
          defaultTranslations={defaultTranslations}
        >
          <TranslationField
            label="Pay Button Label"
            defaultTranslation={defaultTranslations.ticketSubmitButton}
            fieldName="translations.ticketSubmitButton"
            control={control}
          />
          <TranslationField
            label="Payment Header"
            defaultTranslation={defaultTranslations.payment}
            fieldName="translations.payment"
            control={control}
          />
          <TranslationField
            label="Success Message"
            defaultTranslation={defaultTranslations.ticketSuccessMessage}
            fieldName="translations.ticketSuccessMessage"
            control={control}
          />
          <TranslationField
            label="Form Edited Message"
            defaultTranslation={defaultTranslations.ticketEditMessage}
            fieldName="translations.ticketEditMessage"
            control={control}
          />
          <TranslationField
            label="Closing Message"
            defaultTranslation={defaultTranslations.ticketClosingMessage}
            fieldName="translations.ticketClosingMessage"
            control={control}
          />
        </BoxWithEditModal>
        <BoxWithEditModal
          title="RSVP Forms"
          event={event}
          Modal={RsvpFormsModal}
          onSubmit={onSubmit}
          defaultTranslations={defaultTranslations}
        >
          <TranslationField
            label="Confirm"
            defaultTranslation={defaultTranslations.rsvpConfirm}
            fieldName="translations.rsvpConfirm"
            control={control}
          />
          <TranslationField
            label="Decline"
            defaultTranslation={defaultTranslations.rsvpDecline}
            fieldName="translations.rsvpDecline"
            control={control}
          />
          <TranslationField
            label="Plus-One Registration Label"
            defaultTranslation={defaultTranslations.rsvpPlusOneLabel}
            fieldName="translations.rsvpPlusOneLabel"
            control={control}
          />
          <TranslationField
            label="Response Yes"
            defaultTranslation={defaultTranslations.rsvpPlusOneYes}
            fieldName="translations.rsvpPlusOneYes"
            control={control}
          />
          <TranslationField
            label="Response No"
            defaultTranslation={defaultTranslations.rsvpPlusOneNo}
            fieldName="translations.rsvpPlusOneNo"
            control={control}
          />
          <TranslationField
            label="Submit Button Label"
            defaultTranslation={defaultTranslations.rsvpSubmitButton}
            fieldName="translations.rsvpSubmitButton"
            control={control}
          />
          <TranslationField
            label="Success Message"
            defaultTranslation={defaultTranslations.rsvpSuccessMessage}
            fieldName="translations.rsvpSuccessMessage"
            control={control}
          />
          <TranslationField
            label="Form Edit Message"
            defaultTranslation={defaultTranslations.rsvpEditMessage}
            fieldName="translations.rsvpEditMessage"
            control={control}
          />
          <TranslationField
            label="Closing Message"
            defaultTranslation={defaultTranslations.rsvpClosingMessage}
            fieldName="translations.rsvpClosingMessage"
            control={control}
          />
        </BoxWithEditModal>
        <BoxWithEditModal
          title="Waitlist Forms"
          event={event}
          Modal={WaitlistFormsModal}
          onSubmit={onSubmit}
          defaultTranslations={defaultTranslations}
        >
          <TranslationField
            label="Submit Button Label"
            defaultTranslation={defaultTranslations.waitlistSubmitButton}
            fieldName="translations.waitlistSubmitButton"
            control={control}
          />
          <TranslationField
            label="Success Message"
            defaultTranslation={defaultTranslations.waitlistSuccessMessage}
            fieldName="translations.waitlistSuccessMessage"
            control={control}
          />
          <TranslationField
            label="Form Edited Message"
            defaultTranslation={defaultTranslations.waitlistEditMessage}
            fieldName="translations.waitlistEditMessage"
            control={control}
          />
          <TranslationField
            label="Closing Message"
            defaultTranslation={defaultTranslations.waitlistClosingMessage}
            fieldName="translations.waitlistClosingMessage"
            control={control}
          />
        </BoxWithEditModal>
      </Box>
    </Box>
  )
}

export default FormSpecificMessagingSection
