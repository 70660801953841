import React from "react"
import {
  Box,
  Heading, HStack,
  Stack,
  Table,
  Tbody,
  Text,
  Thead,
} from "@chakra-ui/react"
import Button from "components/Buttons/Button"
import {
  User, Account, ModalName, AccountUser,
} from "sharedTypes"
import pluralize from "pluralize"
import { asDate } from "utilities/strings"
import _ from "lodash"
import useModal from "services/useModal"
import { useMutation, useQueryClient } from "react-query"
import * as api from "api/accounts"
import { removeAccountUser } from "context/actions"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { useHistory } from "react-router-dom"
import { accountTeamMemberPath } from "utilities/routes"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import { successToast } from "utilities/toasts"
import AlertBoxWrapper from "components/elements/AlertBox/AlertBoxWrapper"
import SectionHeader from "../../EventShow/Settings/SectionHeader"
import TeamMemberWithAvatar from "./components/TeamMemberWithAvatar"
import {
  Td, TheadTr, Tr, Th,
} from "./components/Table"

type Props = {
  currentUser: User;
  account: Account;
  teamMembers: AccountUser[];
}

const access = ({ owner, admin, events }: AccountUser) => {
  if (owner) {
    return "Account Owner"
  }

  if (admin) {
    return "Account Admin"
  }

  const eventCount = events.length

  return <Text {...!eventCount ? { color: "red.500" } : {}}>{eventCount} {pluralize("Events", eventCount)}</Text>
}

const accountUserString = (accountUser) => {
  const { user } = accountUser
  const name = user ? `${user.firstName} ${user.lastName}` : null
  const email = user?.email || accountUser.email

  if (name) {
    return `${name} (${email})`
  }

  return email
}

const TeamMembersList = ({
  account, currentUser, teamMembers = [],
}: Props) => {
  const showModal = useModal()
  const maxMembersCount = account.subscription?.licenses || 1
  const membersCount = teamMembers?.length
  const remainingMembersCount = _.max([maxMembersCount - membersCount, 0])

  const { accountAdmins, limitedAccess, noAccess } = teamMembers
    .sort((tm) => (tm.user?.id === currentUser.id ? -1 : 1))
    .reduce(
      (sorted, tm) => {
        if (tm.admin || tm.owner) {
          return { ...sorted, accountAdmins: [...sorted.accountAdmins, tm] }
        }
        if (tm.events.length) {
          return { ...sorted, limitedAccess: [...sorted.limitedAccess, tm] }
        }

        return { ...sorted, noAccess: [...sorted.noAccess, tm] }
      }, {
        accountAdmins: [] as AccountUser[],
        limitedAccess: [] as AccountUser[],
        noAccess: [] as AccountUser[],
      },
    )

  return (
    <Box px={32} py={8} minW={980}>
      <Stack mb={12}>
        <SectionHeader
          actionButtons={
            [
              <Button
                isDisabled={!remainingMembersCount}
                leftIcon="plus"
                onClick={() => showModal(ModalName.AccountUserForm)}
              >
                Invite Member
              </Button>,
            ]
          }
          headingSize="4xl"
        >Team Members
        </SectionHeader>
        <Stack direction="row" spacing={10} fontSize="lg" fontWeight="bold">
          <Text>Team Size: {maxMembersCount}</Text>
          <Text>Active: {membersCount}</Text>
          <Text>Remaining: {remainingMembersCount}</Text>
        </Stack>
      </Stack>
      <TeamMembersTable
        title={limitedAccess.length + noAccess.length ? "Account Admins" : null}
        teamMembers={accountAdmins}
        currentUser={currentUser}
      />
      <TeamMembersTable
        title="Limited Access"
        teamMembers={limitedAccess}
        currentUser={currentUser}
      />
      <TeamMembersTable title="No Access" teamMembers={noAccess} currentUser={currentUser} />
      {!remainingMembersCount && (
        <AlertBoxWrapper>
          <UpgradeAlert text="All team member licences have been used." />
        </AlertBoxWrapper>
      )}
    </Box>
  )
}

const TeamMembersTable = ({ title, teamMembers, currentUser }) => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const { mutate: deleteTeamMember } = useMutation(
    (teamMember: AccountUser) => api.deleteAccountUser(teamMember), {
      onSuccess: (data, teamMember) => {
        removeAccountUser(queryClient, teamMember)
        successToast({ title: `${accountUserString(teamMember)} has been deleted` })
      },
    },
  )

  if (!teamMembers.length) {
    return null
  }

  return (
    <>
      {title && <Heading mt={12} fontSize="xl">{title}</Heading>}
      <Table mt={4} mb={12}>
        <Thead>
          <TheadTr>
            <Th>Name &amp; Email</Th>
            <Th>Access</Th>
            <Th>Invited</Th>
            <Th>&nbsp;</Th>
          </TheadTr>
        </Thead>
        <Tbody>
          {teamMembers.map((teamMember) => {
            const {
              id, createdAt, user,
            } = teamMember

            const isYou = user?.id === currentUser.id

            return (
              <Tr key={id}>
                <Td>
                  <TeamMemberWithAvatar teamMember={teamMember} isCurrent={isYou} />
                </Td>
                <Td fontWeight="semibold">{access(teamMember)}</Td>
                <Td>{asDate(createdAt, "MMMM d, yyyy")}</Td>
                <Td textAlign="right">
                  {!isYou && (
                    <HStack justifyContent="flex-end">
                      <Button
                        size="sm"
                        variant="outline"
                        onClick={() => history.push(accountTeamMemberPath(id.toString()))}
                      >
                        Edit
                      </Button>
                      <ConfirmDelete
                        type="Team Member"
                        name={`${accountUserString(teamMember)}`}
                        from="your account"
                        description="If you proceed, this user will be removed from all events."
                      >
                        <Button
                          size="sm"
                          colorScheme="button.danger"
                          variant="outline"
                          onClick={() => deleteTeamMember(teamMember)}
                        >
                          Delete
                        </Button>
                      </ConfirmDelete>
                    </HStack>
                  )}
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </>
  )
}

export default TeamMembersList
