import React from "react"
import SidePanelHeader from "components/Builder/components/SidePanelHeader"
import { Control } from "react-hook-form"
import { FormType, Translations } from "sharedTypes"
import ManagePlusOnes from "../EditFormModalContent/EditFormModalSettings/ManagePlusOnes"
import MessagingSection from "../EditFormModalContent/EditFormModalSettings/Messaging"

type Props = {
  control: Control
  defaultTranslations: Translations
  type: FormType
  formId: string
  updateEvent: (params: {}) => Promise<void>
}

const PlusOneSettings = ({
  control, defaultTranslations, type, formId, updateEvent,
}: Props) => (
  <>
    <SidePanelHeader>Plus-Ones</SidePanelHeader>
    <ManagePlusOnes control={control} />
    <MessagingSection
      updateEvent={updateEvent}
      defaultTranslations={defaultTranslations}
      formType={type}
      formId={formId}
    />
  </>
)

export default PlusOneSettings
