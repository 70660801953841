import React from "react"
import { Flex, HStack, Text } from "@chakra-ui/react"
import SubmissionCard from "pages/EventShow/Submissions/FormSubmissions/FormSubmissionsDetailView/SubmissionCard"
import Icon from "components/elements/Icon"
import { pick } from "lodash"
import { asMoney } from "utilities/strings"
import { submissionIsPaid } from "utilities/forms"
import { DioboxEvent, SubmissionLineItem } from "sharedTypes"
import LineItemShow from "./LineItemShow"

type Props = {
  lineItem: SubmissionLineItem
  event: DioboxEvent
}

const LineItemDetailCard = ({
  lineItem,
  event,
}: Props) => {
  const {
    form: {
      formData: { blocks },
    },
  } = lineItem

  const { portalPrimaryColor, translations } = event

  return (
    <SubmissionCard
      header={(
        <Flex
          justify="space-between"
          fontSize="xl"
          fontWeight="semibold"
          w="100%"
        >
          <HStack fontWeight="semibold">
            <Icon icon={`form-${lineItem.form.type.toLowerCase()}`} size={6} />
            <Text>{lineItem.name}</Text>
            {submissionIsPaid(lineItem.price) && (
            <Text>{asMoney(lineItem.price, lineItem.currency)}</Text>
            )}
          </HStack>
        </Flex>
        )}
    >
      <LineItemShow
        guest={pick(lineItem.guest, [
          "firstName",
          "lastName",
          "name",
          "email",
          "companyName",
          "companyTitle",
          "phone",
          "address",
          "status",
        ])}
        fields={pick(lineItem.form, [
          "profileNameEnabled",
          "profileEmailEnabled",
          "profileCompanyEnabled",
          "profileTitleEnabled",
          "profilePhoneEnabled",
          "profileAddressEnabled",
          "profileCityEnabled",
          "profileStateEnabled",
          "profileZipEnabled",
          "profileCountryEnabled",
        ])}
        formType={lineItem.form.type}
        primaryColor={portalPrimaryColor}
        translations={translations}
        responses={lineItem.responses || {}}
        blocks={blocks}
      />
    </SubmissionCard>
  )
}

export default LineItemDetailCard
