import React from "react"
import NoContent from "components/elements/NoContent"
import useModal from "services/useModal"
import { ModalName } from "sharedTypes"
import AlertBoxWrapper from "components/elements/AlertBox/AlertBoxWrapper"
import { useIntercom } from "react-use-intercom"
import AlertBox, { AlertBoxType } from "components/elements/AlertBox/AlertBox"

type Props = {
  enabled: boolean
}

const NoTexts = ({ enabled }: Props) => {
  const showModal = useModal()
  const { show: showIntercom } = useIntercom()

  return (
    <>
      {!enabled && (
        <AlertBoxWrapper absolute>
          <PendingTextVerificationAlert contactUs={showIntercom} />
        </AlertBoxWrapper>
      )}
      <NoContent
        title="Texting"
        icon="chat"
        descriptionItems={["Send bulk SMS messages to invite guests to register for your event or to provide them with confirmation links, directing them to their confirmation pages and QR codes."]}
        callsToActions={[{
          icon: "plus-big",
          text: "Create First Text",
          action: () => showModal(ModalName.NewTextMessage),
          isButton: true,
        }, {
          icon: "info",
          text: "Learn more about Diobox Texting",
          action: () => {},
        }]}
      />
    </>
  )
}

const PendingTextVerificationAlert = ({ contactUs }) => (
  <AlertBox
    type={AlertBoxType.Verification}
    text="Text sending is disabled by default for all newly created accounts to prevent spammers from abusing Diobox. You can still build your campaigns on this page."
    header="Your account is pending verification for text sending"
    buttonText="Contact us to enable text sending"
    onClick={contactUs}
  />
)

export default NoTexts
